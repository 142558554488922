/*
/ _settings.breakpoints.scss
*/

$width-device-large-lower: 1201px;
$width-device-medium-upper: 1200px;
$width-device-medium-lower: 721px;
$width-device-small-upper:  720px;

:export {
  largeDeviceMinWidth: strip-unit($width-device-large-lower);
  mediumDeviceMinWidth: strip-unit($width-device-medium-lower);
  mediumDeviceMaxWidth: strip-unit($width-device-medium-upper);
  smallDeviceMaxWidth: strip-unit($width-device-small-upper);
}

@mixin breakpoint($point) {
  @if $point == large {
    @media (min-width: $width-device-large-lower) { @content ; }
  }
  @else if $point == medium {
    @media (min-width: $width-device-medium-lower) and (max-width: $width-device-medium-upper) { @content ; }
  }
  @else if $point == medium_down {
    @media (max-width: $width-device-medium-upper) { @content ; }
  }
  @else if $point == medium_up {
    @media (min-width: $width-device-medium-lower) { @content ; }
  }
  @else if $point == no_medium {
    @media (max-width: $width-device-small-upper) and (min-width: $width-device-large-lower) { @content ; }
  }
  @else if $point == small {
    @media (max-width: $width-device-small-upper) { @content ; }
  }
}
