
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-main-photo-view {
  justify-content: space-between;
  display: flex;
  flex-flow: column;

  @include breakpoint(medium_up) {
    padding: 18px 20px;
    height: 100vh;
  }
  @include breakpoint(small) {
    padding-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  &__title {
    line-height: 40px;
    color: $color-neutral-one;
    cursor: default;

    @include breakpoint(medium_up) {
      padding-left: 10px;
      font-size: 26px;
      padding-bottom: 16px;
    }

    @include breakpoint(small) {
      font-size: 22px;
      padding-left: 14px;
    }
  }

  &__image-container {
    min-height: 200px;
    display: flex;
    background-color: $color-neutral-three;
    flex: 1;
    position: relative;
  }

  &__tool-bar {
    display: flex;
    width: 100%;

    @include breakpoint(medium_up) {
      padding-left: 14px;
    }
    @include breakpoint(small) {
      height: 71px;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: right;

    @include breakpoint(medium_down) {
      width: 100%;
      justify-content: space-around;
    }
    @include breakpoint(medium_up) {
      align-items: center;
      height: 110px;
    }

    @include breakpoint(medium) {
      justify-content: flex-end;
    }

    @include breakpoint(large) {
      justify-content: space-between;
    }

    @include breakpoint(small) {
      padding: 20px 15px;
    }
  }

  &__help {
    margin-left: 27px;
  }

  &__button {
    color: $color-accent-one;

    @include breakpoint(large) {
      width: 210px;
      margin-right: 30px;
    }

    @include breakpoint(medium) {
      width: 210px;
      margin-right: 30px;
    }

    @include breakpoint(small) {
      width: 100%;
    }
  }

  &__tool-name {
    font-family: $font-body;
    font-weight: $font-weight-semibold;
  }
}
