
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  padding: 8px 8px 7px 11px;
  background-color: $color-neutral-three;

  &__name {
    flex: 2;
    font-size: $font-size-medium;
    line-height: 1.5;
    word-break: break-word;
    color: $color-neutral-one;
  }

  &__controls {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__progress {
    flex: 1;
  }

  &__button {
    @include removeDefaultButtonStyling;
    color: $color-brand-one;
    font-size: 37px;
    margin-left: 10px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-accent-one;
    }
  }
}

.g-bg-secondary,
.c-input-group--secondary {
  .c-file-preview {
    background-color: $white;
  }
}
