
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$audio-track-bg: $color-neutral-one;
$audio-track-active-bg: $color-brand-two;
$audio-track-hover-bg: $color-neutral-one;
$audio-track-control-bar-bg: $color-neutral-one;
$audio-font-color: $white;
$audio-icon-color: $white;
$audio-track-font-size: $font-size-small;
$audio-track-border-color: $color-neutral-two;

.c-audio-track {
  color: $audio-font-color;
  font-size: $audio-track-font-size;
  background-color: $audio-track-bg;
  transition: 0.3s;
  display: flex;
  border-top: 1px solid $audio-track-border-color;
  cursor: pointer;
  align-items: center;
  height: 38px;

  &:hover {
    background-color: $audio-track-hover-bg;
  }

  &:focus,
  &:active {
    background-color: $audio-track-hover-bg;
  }

  &:focus,
  &:active &__name {
    text-decoration: underline;
  }

  &--active {
    background-color: $audio-track-active-bg;
  }

  &__icon {
    min-width: 28px;
    height: 28px;
    border: 2px solid #fff;
    text-align: center;
    border-radius: 28px;
    margin: 0 11px;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -5px;
      font-size: $font-size-tiny;
    }
  }

  &__name {
    width: 100%;
  }

  &__duration {
    margin-right: 10px;
  }
}
