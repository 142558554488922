
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-info-section {
  padding: 10px;
  background: $color-neutral-three;

  & p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.warning {
    border: solid 1px $cardinal-red;
    background-color: rgba($cardinal-red, 0.1);
  }

  & a:not([class]) {
    color: $color-text-alternative-link;

    &:hover {
      color: $color-text-alternative-link;
      text-decoration: underline;
    }
  }
}
