
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-gallery-photo-view {
  justify-content: space-between;
  display: flex;
  flex-flow: column;

  @include breakpoint(medium_up) {
    padding: 18px 20px;
    height: 100vh;
  }
  @include breakpoint(small) {
    padding-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  &__title {
    line-height: 40px;
    color: $color-neutral-one;
    cursor: default;

    @include breakpoint(medium_up) {
      padding-left: 10px;
      font-size: 26px;
      padding-bottom: 16px;
    }

    @include breakpoint(small) {
      font-size: 22px;
      padding-left: 14px;
    }
  }

  &__image-container {
    min-height: 200px;
    display: flex;
    background-color: $color-neutral-three;
    flex: 1;
    position: relative;
  }

  &__tool-bar {
    border-bottom: 1px solid $medium-grey-3;
    display: flex;
    width: 100%;

    @include breakpoint(medium_up) {
      padding-left: 14px;
    }
    @include breakpoint(small) {
      height: 71px;
    }
  }

  &__additional-panel {
    padding: 20px 0;
    border-bottom: 1px solid $medium-grey-3;
    font-weight: $font-weight-semibold;

    @include breakpoint(medium_up) {
      padding-left: 30px;
    }

    @include breakpoint(small) {
      padding-left: 23px;
    }
  }

  &__tool-name {
    font-family: $font-body;
    font-weight: $font-weight-semibold;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium_up) {
      padding: 30px;
      height: 120px;
    }
    @include breakpoint(small) {
      height: 150px;
      padding: 20px 15px;
    }
  }

  &__buttons {
    display: flex;

    @include breakpoint(medium_down) {
      width: 100%;
      justify-content: space-around;
    }

    @include breakpoint(small) {
      width: 100%;
      flex-wrap: wrap;
      flex-flow: column-reverse;
    }
  }

  &__button {
    @include breakpoint(large) {
      margin-left: 30px;
      width: 210px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: 210px;
    }

    @include breakpoint(small) {
      width: 100%;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  &__help {
    display: flex;
    height: 50px;
  }
}
