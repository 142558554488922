
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-edit-video-form {
  .c-thumbnail-video-viewer {
    margin-bottom: 25px;
  }

  &__control {
    padding: 0;
    margin-bottom: 40px;

    .c-helper__trigger {
      font-weight: $font-weight-semibold;
    }
  }

  &__input {
    & .c-input-label {
      margin-bottom: 14px;
    }
  }

  &__thumbnails {
    margin: 0 0 40px;

    &-heading {
      margin: 0 0 10px;
    }
  }

  &__loading {
    position: relative;
    width: 100%;
    margin-bottom: 15px;

    &-thumbnail {
      padding-top: 56.25%;
      margin-bottom: 5px;
      position: relative;

      &-cover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      & .c-shimmering-stub:after {
        top: -130px;
      }
    }

    &-title {
      margin-top: 11px;
      width: 50%;
      height: 12px;
    }
  }
}
