
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$gradient: linear-gradient(99deg, $color-accent-one 0%, $color-brand-one 100%);

.subscription-card {
  @apply pt-[10px] px-[1px] pb-[1px] rounded-[10px] opacity-90 cursor-pointer;

  &--classic {
    @apply bg-grey-medium-3 col-span-12 md:col-span-6 md:col-start-4;
  }

  &--premium {
    @apply bg-grey-medium-3 col-span-12 md:col-span-7;
    background: $gradient;
  }

  &__body {
    @apply rounded-b-[10px] h-full relative flex flex-col;
  }

  &--selected {
    @apply shadow-[0_0_10px_0_#1c1c1c40] px-[2px] pb-[2px] opacity-100;
  }

  .header-block {
    @apply flex flex-col justify-between items-center;
    @apply px-[25px] pt-5 mb-5;

    p {
      @apply leading-[22px];
    }

    &__header {
      @apply text-[1.78rem] font-semibold;
    }

    &__text {
      @apply text-color-neutral-one mt-5 text-center;
    }

    &__price {
      @apply font-bold text-[1.78rem] text-color-neutral-one;
    }
  }

  .promo-block {
    @apply text-center font-semibold text-[1.2rem] text-color-neutral-one bg-color-accent-one py-[9px] mb-5;
  }

  .description-block {
    @apply px-[35px] py-[10px];

    &__header {
      @apply text-[0.89rem] leading-[22px] text-color-neutral-one border-b border-b-grey-medium-3 pb-2;
    }

    &__benefit {
      @apply flex gap-[18px] text-[0.89rem] mt-[15px];
    }

    &__benefit-icon {
      @apply w-[22px] h-[22px] mt-[2px];
    }
  }

  .show-benefits-button {
    @apply border-none focus:shadow-none focus:no-underline;

    &__text {
      @apply flex items-center gap-[10px];

      &--classic {
        @apply text-color-brand-one;

        svg {
          path {
            @apply fill-color-brand-one;
          }
        }
      }

      &--premium {
        color: $gradient;
      }
    }
  }

  .cta-section {
    @apply flex pb-5 justify-end items-center flex-col flex-1;

    &__button {
      @apply w-[312px] text-center pt-3 pb-[15px] font-semibold rounded-[1px];

      &--classic {
        @apply text-color-brand-one border-[3px] border-color-brand-one;
      }

      &--premium {
        @apply text-color-neutral-one;
        background: $gradient;
      }
    }
  }
}
