
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-header-section {
  &__navigation-menu {
    z-index: 1000;
    max-height: 100%;
  }
}

.c-popup .c-localization-dialog .c-input-wrapper {
  margin-bottom: 0;
}
