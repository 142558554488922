
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$card: c-card;
$card-padding-normal: 15px;
$card-padding-small: 10px;
$card-icon-size: 25px;

$card-height: 66px;
$card-height-tall: 87px;

.#{$card} {
  position: relative;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.4);
  background-color: $white;
  overflow: hidden;

  &.#{$card}--active,
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  }

  &--clickable {
    cursor: pointer;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__expiration-warning,
  &__expired-warning {
    font-size: $font-size-tiny;
    color: $white;
    background-color: $burnt-orange;
    padding: 5px 10px;
    position: absolute;
    width: 100%;
    bottom: 0;

    .#{$card}--small & {
      @include breakpoint(large) {
        padding: 5px $card-padding-small;
      }
    }

    @include breakpoint(large) {
      padding: 5px $card-padding-normal;
    }
  }

  &__expired-warning {
    @apply bg-red-cardinal;
  }

  &__name-link {
    display: inline;
    vertical-align: top;
    font-size: $font-size-card-name;
    font-weight: $font-weight-card-name;
    color: $color-account-type-default;

    &:hover {
      text-decoration: underline;
    }
  }

  &__name {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    line-height: 18px;
  }

  // Keep this block separately
  &__name {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__member-type,
  &__performer-age,
  &__performer-agent-wrapper {
    font-size: $font-size-card-member-type;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  &__performer-agent {
    display: inline;
    vertical-align: top;
    line-height: inherit;
  }

  &__performer-agent-wrapper {
    display: block;
    padding-top: 5pt;
  }

  &__image-container {
    width: 100%;
    overflow: hidden;
    padding-top: 125%;
    position: relative;
    background-color: $color-background-card-image-container;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &__content {
    padding: $card-padding-normal;

    .#{$card}--small & {
      padding: $card-padding-small;
      @include breakpoint(large) {
        height: $card-height + (2 * $card-padding-small); //86
      }
    }

    .#{$card}--tall & {
      min-height: $card-height-tall + (2 * $card-padding-normal); //117
      height: auto;

      @include breakpoint(large) {
        padding: $card-padding-small;
      }
    }

    @include breakpoint(large) {
      height: $card-height + (2 * $card-padding-normal); //96
    }

    @include breakpoint(medium_down) {
      padding: $card-padding-small;
      height: $card-height + (2 * $card-padding-small); //86
    }
  }

  &__icons-section {
    padding-top: 5px;
  }

  &__icon {
    font-size: $card-icon-size;
    height: $card-icon-size;
    width: $card-icon-size;
    line-height: $card-icon-size;

    &--clickable {
      color: $color-brand-one;
      background-color: $color-neutral-three;
      border-radius: 4px;
      margin-right: 5px;
      cursor: pointer;

      &:hover {
        color: $color-brand-two;
        background-color: $color-neutral-four;
      }

      &:focus {
        color: $color-brand-one;
        background-color: $color-neutral-three;
        outline: solid 2px $color-brand-two;
      }
    }
  }
}
