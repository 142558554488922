
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.question-tooltip {
  @apply flex flex-row-reverse;

  &__tooltip-wrapper {
    @apply w-5 h-5;
  }

  &__question-mark {
    @apply w-5 h-5 text-lg leading-5 text-center cursor-pointer rounded-3xl shadow-[0px_1px_4px_0px_#1c1c1c80];

    &.primary {
      @apply text-white bg-color-brand-one;
    }

    &.secondary {
      @apply bg-white text-color-brand-one;
    }
  }
}
