
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$inbox-row-border-radius: 4px;

.c-inbox-row {
  $c: c-inbox-row;
  color: $color-neutral-one;
  position: relative;

  &--read {
    .#{$c} {
      &__content {
        background-color: $white;
        border-color: $light-grey-2;

        &::before {
          display: none;
        }
      }

      &__title {
        font-weight: $font-weight-regular;
      }
    }
  }

  &__content {
    @include grid-content-area;
    grid-template-columns: repeat($grid-columns, 1fr);
    width: 100%;
    padding: 10px 15px;
    border-radius: $inbox-row-border-radius;
    border: 1px solid $color-border;
    background-color: $color-neutral-three;
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &:focus,
    &:hover {
      outline: 0;
      background: $color-neutral-four;

      .c-checkbox .c-checkbox__box {
        background-color: $white;
      }
    }

    @include breakpoint(small) {
      grid-gap: 7px;
    }

    a,
    label {
      position: relative;
      z-index: 1;
    }

    .c-checkbox .c-checkbox__box {
      transition: background-color 0.2s ease;
    }

    &::before {
      content: "";
      width: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: $inbox-row-border-radius 0 0 $inbox-row-border-radius;
      background: $color-neutral-one;
      pointer-events: none;
    }
  }

  &__sender {
    display: flex;
    align-items: flex-start;

    @include breakpoint(small) {
      margin-bottom: 2px;
    }
  }

  &__sender-info {
    margin-left: 10px;
  }

  &__sender-name {
    margin-top: 2px;
    word-break: break-word;
    font-weight: $font-weight-semibold;
    font-size: $font-size-medium;

    a:not([class]) {
      @include secondary-link;

      &:hover,
      &:focus {
        text-decoration: underline;
        outline: 0;
      }
    }
  }

  &__sender-name-label {
    display: flex;
    gap: 4px;
    margin-top: 5px;
  }

  &__title-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-medium;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Keep this block separately
  &__title {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__title-tags {
    margin-top: 10px;
  }

  &__additional-info {
    font-size: $font-size-small;
    line-height: 1.25;

    @include breakpoint(small) {
      max-width: 50%;
      margin-right: 15px;
      flex-shrink: 0;
      font-size: $font-size-tiny;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__additional-info-container {
    display: flex;
  }
}
