
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.switch {
  @apply relative w-full flex gap-3;

  .slider-wrapper {
    @apply relative min-w-[54px] h-[32px] bg-color-neutral-three duration-[0.4s] transition-colors cursor-pointer border-[1px];
    @apply border-color-neutral-two rounded-[34px];

    &:focus-within {
      @apply outline outline-2 outline-blue-focus;
    }

    .slider {
      @apply duration-[0.4s] absolute h-6 w-6 left-1 top-[3px] rounded-[34px] bg-color-neutral-two text-center;

      &:before {
        @apply content-["\00d7"] text-white text-center text-[30px] leading-6 font-thin;
      }
    }

    &:has(input:checked) {
      @apply bg-color-brand-one border-color-brand-one;

      .slider {
        @apply bg-white translate-x-5;

        &:before {
          @apply content-[""] absolute w-[8px] h-[12px] top-[5px] left-[8px] rotate-45;
          @apply border-r-[3px] border-b-[3px] border-color-brand-one rounded-[1px];
        }
      }
    }

    input {
      @apply relative opacity-0 z-10 size-full cursor-pointer;
    }
  }

  .label-wrapper {
    @apply flex items-center;

    label {
      @apply cursor-pointer leading-4 mb-2.5;
    }

    .hint {
      @apply text-s;
    }

    &.with-hint {
      @apply self-start flex-col items-baseline;
    }

    &.label-bold {
      label {
        @apply font-semibold;
      }
    }
  }
}
