
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-page-content {
  margin: 0 auto;
  max-width: $global-width;

  &--actions-footer {
    margin-bottom: 50px;
  }
}
