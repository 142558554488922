
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "../../../Atoms/FilterTag/FilterTag";

$clear-all-button-section-width: 76px;

.c-tags-panel {
  $c: c-tags-panel;

  overflow: hidden;
  height: 0;
  max-height: 0;
  min-width: 165px;
  transition-property: height, margin-bottom, margin-top, max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  position: relative;

  &--with-tags {
    height: auto;
    max-height: 32px; // 30px + 2px to allow for 1px border on tags
    margin-bottom: 10px;

    @include breakpoint(small) {
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &__clear-all {
    display: inline-block;
    margin-left: 20px;
    @include breakpoint(small) {
      margin-left: 15px;
    }
  }

  .#{$c}__tag-counter {
    margin-left: 0;
    @include breakpoint(medium_up) {
      display: none;
    }
  }

  .#{$c}__active-tag {
    @include breakpoint(small) {
      display: none;
    }
  }

  .#{$c}__more-tags {
    @include breakpoint(small) {
      display: none;
    }
  }

  &__hidden-calc-area {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    pointer-events: none;
    width: calc(100% - 76px);
    &__unit {
      @include filter-tag-base;
      @include filter-tag-base--with-icon;
      margin-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: $font-weight-semibold;
    }
  }
}
