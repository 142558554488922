
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-full-page-spinner {
  &__container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-neutral-three, 0.4);
  }

  &__spinner {
    position: sticky;
    top: calc(50vh - 80px);
    z-index: 1000;
  }
}
