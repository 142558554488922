
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.payment-method {
  @apply flex items-center gap-5 bg-color-neutral-three px-5 border border-color-neutral-one border-dashed rounded-[5px] cursor-pointer;

  &--primary {
    @apply text-color-brand-one font-semibold py-[34px];

    svg {
      path {
        @apply fill-color-brand-one;
      }
    }
  }

  &--secondary {
    @apply py-6;
  }

  &--selected {
    @apply bg-white border-color-neutral-one border-solid border-l-[4px] border-l-color-brand-two;

    svg:not(.payment-method__logo) {
      @apply bg-color-brand-two rounded-full p-1;
      * {
        @apply bg-color-brand-two fill-white;
      }
    }
  }
}
