
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$loading-audio-tile-height: 75px;

.c-edit-audio-form {
  .c-audio-viewer {
    margin-bottom: 25px;
    margin-top: 20px;
  }

  &__control {
    padding: 0;
    margin-bottom: 40px;

    .c-helper__trigger {
      font-weight: $font-weight-semibold;
    }
  }

  &__input {
    & .c-input-label {
      margin-bottom: 14px;
    }

    &--loading {
      min-height: 91px;
      margin-bottom: 40px;
    }
  }

  &--loading {
    position: relative;
    width: 100%;    
    height: $loading-audio-tile-height;
    margin-bottom: 25px;
    margin-top: 20px;
  }
}
