
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-file-progress-bar {
  position: relative;
  height: 8px;
  border-radius: 7px;
  border: 1px solid $medium-grey-3;
  background-color: $fog-grey-1;
  overflow: hidden;

  &__filler {
    height: 100%;
    border-radius: 0 8px 8px 0;
    background-color: $color-brand-three;
  }
}
