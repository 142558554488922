
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-button-panel {
  overflow-x: auto;
  white-space: nowrap;

  .c-action-button-holder {
    color: $color-brand-one;
  }

  &__element {
    display: inline-flex;
    justify-content: center;

    @include breakpoint(small) {
      max-width: 58px;
      height: fit-content;
    }
  }
}
