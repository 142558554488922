
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@mixin textStyle {
  font-size: $font-size-tiny;
  line-height: 16px;
  color: $color-text-breadcrumb-bar-primary;
}

.c-breadcrumb-bar {
  background-color: $color-background-breadcrumb-bar-primary;

  &--truncated-mobile {
    @include breakpoint(small) {
      .c-breadcrumb-bar__item {
        display: none;

        &:first-child,
        &:last-child,
        &:nth-last-child(2) {
          display: inline;
        }

        &:first-child::after {
          content: "… / ";
        }
      }
    }
  }

  &__container {
    max-width: $global-width;
    display: flex;
    align-items: center;

    @include breakpoint(large) {
      padding: 2px 15px;
    }
    @include breakpoint(medium) {
      padding: 2px 10px;
    }
    @include breakpoint(small) {
      height: 40px;
      padding: 4px 15px;
    }
  }

  &__list {
    @include removeDefaultListStyling;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    line-height: 0;
  }

  &__item {
    display: inline;
    @include textStyle;

    &::after {
      @include textStyle;
    }
  }

  &__link {
    @include textStyle;
    max-width: 36ch;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      text-decoration: underline;
    }
  }

  &__separator {
    @include textStyle;
  }
}
