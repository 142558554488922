
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-input-wrapper__validation {
  margin-top: 5px;
  margin-left: 1px;
  display: flex;
  align-items: center;
  position: absolute;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-message {
    margin-top: 1px;
    margin-left: 4px;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    width: 100%;
    line-height: normal;

    &--margin {
      margin-left: 24px;
    }
  }

  &--error {
    color: $color-input-validation-error-text;

    svg circle {
      fill: $color-input-validation-error-icon;
    }
  }

  &--warning {
    color: $color-input-validation-warning-text;

    svg circle {
      fill: $color-input-validation-warning-icon;
    }
  }

  &--notification {
    color: $color-input-validation-notification-text;
    margin: unset;

    .c-input-wrapper__validation-message {
      font-style: italic;
      font-weight: $font-weight-regular;
      text-indent: unset;
      margin: unset;
    }
  }
}
