
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-photo-view {
  min-height: 200px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  &__image-wrapper {
    margin: auto;
    position: relative;
    overflow-y: hidden;
    max-height: 100%;
  }

  &__image-id {
    position: absolute;
    height: 32px;
    background-color: rgba($color-neutral-one, 0.78);
    color: $white;
    font-size: 18px;
    line-height: 32px;
    padding: 0 9px;
    top: 0;
    left: 0;
  }
  @include breakpoint(large) {
    &__image-wrapper:hover &__copyright {
      transform: translateY(-100%) !important;
      transition: transform 0.2s;
    }

    &__image-wrapper.active &__copyright {
      transform: translateY(-100%);
      transition: transform 0.2s;
    }

    &__image-wrapper.hidden &__copyright {
      transform: translateY(0);
      transition: transform 0.2s;
    }
  }

  @include breakpoint(medium_down) {
    &__image-wrapper.touched &__copyright {
      transform: translateY(0);
      transition: transform 0.2s;
    }
  }

  &__copyright {
    transition: transform 0.2s;
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0.8;
    background-color: #d8d8d8;
    font-size: 12px;
    color: $color-neutral-one;
    padding: 9px;

    @include breakpoint(large) {
      transform: translateY(0);
    }
    @include breakpoint(medium_down) {
      transform: translateY(-100%);
    }
  }

  &__image {
    display: block;
    margin: auto;
  }
}
