﻿/*
/ _settings.units.scss
*/

// Arrow size
$arrow-medium: 15px;

// Borders
$border-width-thin: 1px;
$border-width-medium: 2px;
$border-width-thick: 3px;

// Outlines
$outline-width-default: 3px;

// Border-radius
$border-radius-small: 3px;
$border-radius-medium: 5px;
$border-radius-full: 100%;

// Outlines
$outline-width-medium: 2px;

// Spacing
$spacing-x-small: calculate-rem(3px);
$spacing-small: calculate-rem(6px);
$spacing-medium: calculate-rem(12px);
$spacing-large: calculate-rem(24px);
$spacing-x-large: calculate-rem(48px);
$spacing-button-vertical: calculate-rem(10px);
$spacing-button-horizontal: calculate-rem(10px);

// Standard touch size;
$touch-size: calculate-rem(50px);

// Checkbox and Radio Selection Size
$checkbox-size: calculate-rem(44px);
$checkbox-size-selected: calculate-rem(30px);
$checkbox-size-actual: 25px;
$checkbox-border-thickness: 1px;
$checkbox-border-thickness-selected: 3px;
$radio-size: calculate-rem(44px);
$radio-size-selected: calculate-rem(30px);

// Left hand navigation menu
$menu-width-medium-up-device: calculate-rem(320px);

// Share icons
$share-icon-size: calculate-rem(20px);

// Modals
$modal-close-spacing: 0 calculate-rem(6px);
$modal-close-size: calculate-rem(40px);

//Carousel
$carousel-inner-padding: calculate-rem(100px);
$max-icon-size: calculate-rem(50px);
$max-icon-size--mobile: calculate-rem(25px);

//Page outer padding
$outer-padding-lg-screen: 15px;
$outer-padding-md-screen: 10px;
$outer-padding-sm-screen: 15px;

//Inputs
$input-height: 50px;
$input-medium-height: 40px;
$input-margin-bottom: 40px;

// Dropdown
$dropdown-border-radius: 4px;
