
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$c: c-simple-grid;

%simple-grid-cell {
  padding-left: 24px;
  padding-right: 12px;
  font-size: $font-size-base;
  text-align: left;
}

.#{$c} {
  &__table {
    width: 100%;

    &-header {
      &-row {
        display: grid;
      }

      &-cell {
        background-color: $shark-grey-5;
        display: inline-block;
        border-left: 1px solid $white;

        &-clickable-space {
          @extend %simple-grid-cell;
          position: relative;
          width: 100%;
          text-overflow: ellipsis;
          display: flex;
          gap: 8px;
          font-weight: $font-weight-semibold;
          text-decoration: none;

          &-title {
            align-self: center;
          }
        }

        &-sort-icon {
          position: absolute;
          right: 20px;
          top: 25px;
          height: 22px;
          fill: $white;
          pointer-events: none;
        }

        &.#{$c}__table-header-cell__clickable {
          .#{$c}__table-header-cell-clickable-space {
            padding-right: 40px;
            cursor: pointer;
          }
        }
      }
    }

    &-body {
      overflow: auto;
      width: 100%;

      &-row {
        background-color: $white;
        border: none;
        border-bottom: 1px solid rgba($color-neutral-one, 0.25);
        min-height: 60px;
        display: grid;
        align-items: center;

        &-cell {
          @extend %simple-grid-cell;
          color: $color-neutral-one;
          padding-top: 14px;
          padding-bottom: 14px;
          font-weight: $font-weight-regular;
          line-height: 1.25;
        }
      }
    }
  }

  &__single-column {
    .#{$c}__table {
      &-body {
        &-row {
          color: red;
          min-height: unset;
          border: unset;
          grid-template-columns: repeat(2, 1fr);
          display: block;

          &:nth-child(odd) {
            background-color: $color-neutral-three;
          }

          &-cell {
            &:first-child {
              padding-bottom: 0;
              .#{$c}__table-body-row-cell-label {
                display: none;
              }
            }

            height: 100%;
            word-break: break-word;
            grid-column: span 2 / auto;

            &-label {
              font-weight: $font-weight-semibold;
            }
          }
        }
      }

      &-header {
        display: none;
      }
    }
  }
}
