
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-radio {
  &__disabled {
    opacity: 0.5;

    * {
      pointer-events: none;
    }

    input.c-radio__input:disabled {
      opacity: 0;
    }
  }

  &__container {
    display: inline-block;
    padding: 10px;
    margin: -10px;
    position: relative;
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;

    &:checked + .c-radio__content .c-radio__box::before {
      background: $color-background-checkbox-selected;
    }
    &:focus + .c-radio__content .c-radio__box {
      outline: 2px solid $blueFocus;
    }
  }

  &__box {
    width: 25px;
    height: 25px;
    border: 1px solid $color-border-radio-default;
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    line-height: 0;
    border-radius: 50%;
    background-color: $color-background-checkbox-default;

    &:before {
      content: "";
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background: transparent;
    }

    & + .c-radio__text {
      margin-left: 14px;
    }
  }

  &__text {
    margin-top: 1px;
    line-height: normal;

    @include breakpoint(small) {
      font-size: $font-size-medium;
    }

    strong,
    b {
      font-weight: $font-weight-semibold;
    }
  }

  &__expanding-area {
    margin: 0 -10px 0 -25px;
  }
}
