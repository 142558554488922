
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-showreel-thumb {
  display: inline-block;
  color: $color-neutral-one;
  cursor: pointer;
  position: relative;
  margin-bottom: 5px;
  width: 100%;

  &__checkbox {
    position: absolute;
    top: 10px;
    right: 10px;

    & .c-checkbox__box {
      background-color: $white;
    }
  }

  &__video {
    background-size: cover;
    background-position: center;
    height: 130px;
    position: relative;
    overflow: hidden;

    @include breakpoint(medium) {
      height: 110px;
    }

    @include breakpoint(small) {
      @apply h-auto w-full aspect-video;
    }

    &--pointer {
      .c-overlay {
        cursor: pointer;
      }
    }

    &--loading {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__play-icon {
    background-position-y: 50%;

    &:before {
      font-size: 47px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
      color: $white;
    }
  }

  &__description {
    display: block;
    color: $color-text-default;

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__file-name {
    width: 100%;
    font-weight: $font-weight-semibold;
    margin: 6px 0 0;
    font-size: $font-size-small;
    line-height: $font-size-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__duration {
    width: 100%;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    line-height: $font-size-base;
    margin: 0;
    min-height: $font-size-base;
  }

  &--hover {
    .c-showreel-thumb__video {
      &:hover {
        .c-showreel-thumb__play-icon {
          &:before {
            color: $color-brand-one;
          }
        }
      }

      &:active,
      &:focus {
        .c-showreel-thumb__play-icon {
          &:before {
            color: $color-accent-one;
          }
        }
      }
    }
  }
}
