
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$audio-track-bar-bg-color: $color-neutral-one;
$audio-track-bar-tape-color: $rich-purple;

.c-audio-time-bar {
  width: 100%;
  cursor: pointer;
  background-color: $audio-track-bar-bg-color;
  height: 4px;
  position: relative;

  &__tape {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $audio-track-bar-tape-color;
    height: 4px;
  }

  &__time-bar {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    height: 4px;
  }
}
