
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-gallery {
  &__header {
    border-bottom: 1px solid map-get($grey, 90);
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding: 0 15px;
    margin-bottom: 30px;
    margin-top: 10px;
    cursor: default;

    @include breakpoint(small) {
      padding: 0;
    }
  }

  &__header-actions {
    height: 100%;
    align-self: center;

    &>a {
      margin-left: 20px;
    }

    .c-add-photo {
      display: inline;
    }
  }

  &__edit-hint {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-bottom: 20px;

    @include breakpoint(small) {
      flex-direction: column;
      &-help {
        margin-top: 20px;
      }
    }
  }

  &__photoset-container:focus {
    outline: 0;
  }

  &__actions {
    margin-top: 40px;

    &.edit {
      margin-top: 20px;
      color: $white;
    }
  }

  &__edit-action-list {
    display: flex;

    @include breakpoint(medium_up) {
      padding-left: 14px;
    }

    @include breakpoint(small) {
      justify-content: space-around;
    }
  }

  .c-action-button {
    margin-right: 20px;

    @include breakpoint(small) {
      margin: 0;
      grid-column: span 1;
    }
  }
}
