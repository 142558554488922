
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$dark-purple: #525096;

.c-space-bar {
  font-family: $font-body;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  color: $color-neutral-one;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  @include breakpoint(small) {
    padding: 0;
  }

  &__bar-container {
    position: relative;
  }

  &__units-container,
  &__loading-container {
    display: flex;
    justify-content: space-between;
  }

  &__entire-value-block {
    height: 10px;
    background-image: linear-gradient(
      to top,
      $color-background-footer-copyright,
      $medium-grey-3
    );
    border-radius: 2px;

    &--loading {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        width: 110px;
        height: 80px;
        background: linear-gradient(
          rgba($medium-grey-3, 0.1),
          $white,
          rgba($medium-grey-3, 0.1)
        );
        opacity: 0.7;
        position: absolute;
        top: -35px;
        z-index: 1;
        transform: rotate(-60deg);
        animation-name: light;
        animation-duration: 4s;
        animation-delay: 0.05s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
      }
    }
  }

  &__used-value-block {
    height: 10px;
    background-image: linear-gradient(to top, $dark-purple, $color-brand-two);
    position: absolute;
    border-radius: 2px 0 0 2px;
    transition: width 1s ease-out;
  }

  &__entire-value-content {
    font-weight: $font-weight-semibold;
  }
}

@keyframes light {
  0% {
    left: -50%;
    margin-left: 0;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
