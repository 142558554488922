﻿/*
/ _settings.colors.scss
*/

// Primary
$primary-blue: #2741e8;
$white: #fff;
$charcoal-black: #1c1c1c;
// Secondary
$ocean-blue: #20016b;
$turquoise-blue: #32ebf9;
$whisper-grey: #f5f4f9;
// Greys
$shark-grey-5: #3d3e3f;
$stone-grey-4: #6e6e77;
$medium-grey-3: #cccccc;
$light-grey-2: #dadada;
$fog-grey-1: #eeeeee;
$snow-grey-0: #f8f8f8;
// Blues
$sky-blue-2: #c9d6f9;
$pale-blue-1: #dbe4fe;
$mist-blue-0: #f0f5ff;
// Accents
$grass-green: #0f893d;
$rich-purple: #5938bb;
$magenta-pink: #cb2ea8;
$mint-green: #50e3c2;
// Tertiary
$burnt-orange: #be431e;
$cardinal-red: #cd263a;
$agent-red: #bc1521;
$river-green: #00786c;
$pale-green: #d2f3ec;
$pale-red: #ffe1ea;
$pale-orange: #fff0de;
$light-green: #a4ca75;
$pacific-blue: #0090ce;
// Others
$transparent: #00000000;
$tagmin-blue: #0289a6;
$blueFocus: #476cb5; // may remove at a future date.
// Social Colours
$facebook-color: #3b5998; // RGB: 59, 89, 152
$twitter-color: #00aced; // RGB: 0, 172, 237
$googleplus-color: #dd4b39; // RGB: 221, 75, 57
$youtube-color: #b00; // RGB: 187, 0, 0
$linkedin-color: #007bb6; // RGB: 0, 123, 182
$pinterest-color: #cb2027; // RGB: 203, 32, 39
$tumblr-color: #32506d; // RGB: 50, 80, 109

// deprecated - to be removed. Do not use.
$grey: (
  40: #666,
  45: #6b6b6b,
  50: #7f7f7f,
  60: #999,
  70: #b2b2b2,
  72: #b4b4b4,
  90: #e5e5e5,
);

// Brand
$color-brand-one: $primary-blue;
$color-brand-two: $ocean-blue;
$color-brand-three: $turquoise-blue;
$color-neutral-one: $charcoal-black;
$color-neutral-two: $stone-grey-4;
$color-neutral-three: $whisper-grey;
$color-neutral-four: $pale-blue-1;
$color-text-on-dark-background: $white;
$color-link-on-dark-background: $white;

// Accents
$color-accent-one: $turquoise-blue;
$color-social-icon: $charcoal-black;
$color-social-icon-hover: $whisper-grey;
$color-background-disabled: $light-grey-2;
$color-text-disabled: $color-neutral-two;

$color-account-type-default: $ocean-blue;
$color-account-type-castingdirector: $pacific-blue;
$color-account-type-agent: $agent-red;
$color-account-type-performer: $light-green;

// Outlines
$color-outline-default: $charcoal-black;
$color-outline-inputs: $color-neutral-two;
$color-outline-tab: $charcoal-black;

// Text
$color-text-default: $charcoal-black;
$color-text-inverse: $color-brand-one;
$color-text-secondary: $color-neutral-two;
$color-text-link: $color-brand-one;
$color-text-link-secondary: $color-brand-two;
$color-text-link-dark-bg: $color-brand-three;
$color-text-pagination-break: $charcoal-black;

$color-text-alternative-link: $color-brand-two;
$color-text-alternative-link-dark: $color-brand-three;

$color-text-error: $color-brand-one;
$color-text-form-default: $charcoal-black;
$color-text-form-secondary: $charcoal-black;
$color-text-form-tertiary: $color-text-on-dark-background;
$color-text-form-placeholder: map-get($grey, 50);
$color-focus: $color-brand-two;
$color-text-copyright: map-get($grey, 45);
$color-text-footer: $light-grey-2;
$color-text-profile-title: $color-neutral-one;
$color-text-profile-subheading: $color-neutral-one;
$color-text-profile-help-title: $color-brand-one;
$color-text-notification-toast-title: $charcoal-black;
$color-text-breadcrumb-bar-primary: $color-neutral-two;
$color-text-breadcrumb-bar-secondary: $stone-grey-4;
$color-text-step-tracker-primary: $color-neutral-two;
$color-text-to-top: $white;

// Backgrounds
$color-background-error: $color-brand-one;
$color-background-warning: $color-brand-one;
$color-background-success: $color-brand-one;
$color-background-default: $light-grey-2;
$color-background-dark: $color-neutral-four;
$color-background-tab-active: $charcoal-black;
$color-background-browserupgrade: $color-brand-one;
$color-background-body: $white;
$color-background-form-default: $white;
$color-background-form-secondary: $color-neutral-three;
$color-background-form-tertiary: $color-background-dark;
$color-background-checkbox-default: $color-neutral-three;
$color-background-checkbox-selected: $mint-green;
$color-background-radio-default: $color-neutral-three;
$color-background-radio-selected: $mint-green;
$color-background-select-default: $color-neutral-three;
$color-background-input-default: $color-neutral-three;
$color-background-input-secondary: $white;
$color-background-input-tertiary: $white;
$color-background-header: $color-neutral-one;
$color-background-footer: $color-neutral-one;
$color-background-footer-copyright: $fog-grey-1;
$color-background-breadcrumb: $color-neutral-one;
$color-background-profile-main: $whisper-grey;
$color-background-item-default: $whisper-grey;
$color-background-item-secondary: $white;
$color-background-item-tertiary: $white;
$color-background-item-accent-default: $white;
$color-background-item-accent-secondary: $whisper-grey;
$color-background-item-accent-tertiary: $whisper-grey;
$color-background-datepicker-cleaner-border: $color-neutral-two;
$color-background-datepicker-cleaner: $color-neutral-two;
$color-background-datepicker: $color-neutral-three;
$color-background-datepicker-tile-selected: $mint-green;
$color-background-datepicker-tile-hover: $color-neutral-three;
$color-background-datepicker-toggle-disabled: $color-background-disabled;
$color-icon-datepicker-toggle-disabled: $color-text-disabled;
$color-background-main-nav-hover: $fog-grey-1;
$color-background-bottom-nav-hover: $fog-grey-1;
$color-background-nav-canvas: $white;
$color-background-nav-canvas-title-hover: $fog-grey-1;
$color-background-card-image-container: darken($light-grey-2, 2.8);
$color-background-header-nav-item-highlight: $color-neutral-one;
$color-background-header-nav-item: $color-neutral-one;
$color-background-page-view-hover-focus: $charcoal-black;
$color-background-pagination-arrow-hover: $whisper-grey;
$color-background-notification-toast-success: rgba(177, 241, 227, 0.95);
$color-background-notification-toast-error: $pale-red;
$color-background-breadcrumb-bar-primary: map_get($grey, 98);
$color-background-to-top: $primary-blue;
$color-background-expanding-area-primary: $color-neutral-three;
$color-background-expanding-area-secondary: $color-neutral-four;
$color-background-expanding-area-tertiary: $color-neutral-three;
$color-background-dropdown-section-label: $color-neutral-four;

// Buttons
$color-background-button-primary: $color-brand-one;
$color-background-button-primary-hover: $ocean-blue;
$color-background-button-primary-focus: $color-brand-two;
$color-background-button-primary-disabled: $color-background-disabled;
$color-border-button-primary: $color-brand-one;
$color-border-button-primary-hover: $ocean-blue;
$color-border-button-primary-disabled: $color-background-disabled;
$color-text-button-primary: $white;
$color-text-button-primary-hover: $white;
$color-text-button-primary-disabled: $color-text-disabled;
$color-text-action-button-tertiary-hover: $turquoise-blue;
$color-background-button-secondary: $white;
$color-background-button-secondary-hover: $sky-blue-2;
$color-border-button-secondary: $color-brand-one;
$color-border-button-secondary-hover: $ocean-blue;
$color-text-button-secondary: $color-brand-one;
$color-text-button-secondary-hover: $ocean-blue;
$color-background-button-tertiary: $transparent;
$color-background-button-tertiary-hover: $color-background-button-secondary-hover;
$color-border-button-tertiary: $light-grey-2;
$color-border-button-tertiary-hover: $light-grey-2;
$color-text-button-tertiary: $charcoal-black;
$color-text-button-tertiary-hover: $charcoal-black;
$color-background-subnavigationitem: $charcoal-black;
$color-background-subnavigationitem-hover: $fog-grey-1;
$color-border-bottom-underline-acount-default: $color-brand-two;
$color-border-bottom-underline-acount-castingdirector: $color-account-type-castingdirector;
$color-border-bottom-underline-acount-performer: $color-account-type-performer;
$color-border-bottom-underline-acount-agent: $color-account-type-agent;

// Borders
$color-border: $color-neutral-two;
$color-border-disabled: $light-grey-2;
$color-border-error: $color-brand-one;
$color-border-form-default: $color-neutral-two;
$color-border-checkbox-default: $color-neutral-two;
$color-border-radio-default: $color-neutral-two;
$color-border-input-default: $color-neutral-two;
$color-border-tab: $light-grey-2;
$color-border-side-header-nav-item: $shark-grey-5;
$color-border-page-view: $light-grey-2;
$color-border-selected-page-view: $color-outline-tab;
$color-border-checkbox-nest: $light-grey-2;
$color-border-button-split-action: $light-grey-2;
$color-border-more-dropdown-item: $light-grey-2;

// Validations
$color-input-validation-error-icon: $cardinal-red;
$color-input-validation-error-border: $cardinal-red;
$color-input-validation-error-text: $cardinal-red;
$color-input-validation-warning-icon: $burnt-orange;
$color-input-validation-warning-border: $burnt-orange;
$color-input-validation-warning-text: $burnt-orange;
$color-input-validation-notification-text: $shark-grey-5;

// Shadows
$color-shadow-notification-toast: rgba($color-neutral-one, 0.3);
$color-shadow-step-tracker-item-hover: rgba($color-neutral-one, 0.55);
