
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-search {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;

  &__without-label {
    .c-input-label {
      margin: 0;
    }
  }

  &__input {
    flex-grow: 1;

    .c-input-wrapper {
      margin: 0;
      input {
        padding-right: 45px;
      }
    }
  }

  &__button-wrapper {
    margin-left: -1px;
    width: 50px;
  }

  &__children-wrapper {
    position: relative;
    height: 0;
    display: flex;
    justify-content: flex-end;
  }

  &__dropdown-tag-wrapper {
    position: relative;
    top: -44px;
    right: 6px;
  }
}
