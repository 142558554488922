
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$thumbnail-item-height: 105px;
$thumbnail-item-height-sm: 83px;
$block-indentation: 20px;
$block-indentation-sm: 10px;
$check-icon-size: 58px;
$check-icon-size-sm: 44px;

.c-thumbnail-bar {
  grid-column-gap: $block-indentation;
  padding: 0;

  @include breakpoint(small) {
    grid-column-gap: $block-indentation-sm;
  }
}

.c-thumbnail-bar-item {
  position: relative;
  height: $thumbnail-item-height;
  margin-bottom: $block-indentation;

  &__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.4;
  }

  &:hover {
    .c-thumbnail-bar-item__layer {
      opacity: 0.2;
      cursor: pointer;
    }
  }

  &:focus {
    outline: none;

    .c-thumbnail-bar-item__layer {
      opacity: 0;
    }
  }

  &--selected {
    &:hover {
      .c-thumbnail-bar-item__layer {
        opacity: unset;
        cursor: default;
      }
    }

    &:focus {
      outline: unset;

      .c-thumbnail-bar-item__layer {
        opacity: unset;
      }
    }

    .c-thumbnail-bar-item__layer {
      background-color: transparent;
      opacity: 1;
      border: solid 2px $mint-green;
      text-align: center;

      .icon-check1 {
        line-height: $thumbnail-item-height;
        font-weight: 900;
        font-size: $check-icon-size;
        color: $mint-green;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      @include breakpoint(small) {
        .icon-check1 {
          line-height: $thumbnail-item-height-sm;
          font-size: $check-icon-size-sm;
        }
      }
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include breakpoint(small) {
    margin-bottom: $block-indentation-sm;
    height: $thumbnail-item-height-sm;
  }
}
