
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-range-slider {
  &.disabled &__range {
    &::-webkit-slider-thumb {
      background: $light-grey-2;
    }

    &::-moz-range-thumb {
      background: $light-grey-2;
    }
  }

  &.disabled &__tape {
    background: $light-grey-2;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-small;
    align-items: flex-end;
    position: relative;
  }

  &__middle {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 9px solid $color-neutral-one;
    position: absolute;
    left: calc(50% - 4px);
    bottom: 0;
  }

  &__extremes {
    color: $color-text-default;
  }

  &__bar {
    background-color: $white;
    border-radius: 15px;
    position: relative;

    @include breakpoint(medium_up) {
      height: 14px;
      margin-top: 15px;
    }

    @include breakpoint(small) {
      height: 10px;
      margin-top: 7px;
    }
  }

  &__tape {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $color-brand-one;
    width: 50%;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }

  &__range {
    position: absolute;
    top: 0;
    margin: 0;
    display: block;
    width: 100%;
    border-radius: 5px;
    background: transparent;
    accent-color: $color-brand-one;

    @include breakpoint(medium_up) {
      height: 30px;
      margin-top: -8px;
    }

    @include breakpoint(small) {
      height: 22px;
      margin-top: -6px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      background: $color-brand-one;
      cursor: pointer;
      box-shadow: 0 0 4px 0 rgba($color-neutral-one, 0.25);

      @include breakpoint(medium_up) {
        width: 27px;
        height: 27px;
      }

      @include breakpoint(small) {
        width: 21.6px;
        height: 21.6px;
      }
    }

    &::-moz-range-thumb {
      border-radius: 50%;
      background: $color-brand-one;
      cursor: pointer;

      @include breakpoint(medium_up) {
        width: 27px;
        height: 27px;
      }

      @include breakpoint(small) {
        width: 21.6px;
        height: 21.6px;
      }
    }
  }
}
