
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "../../GlobalStyles/scrollbar";

$dropdown: c-dropdown;
$dropdown-options-max-height: 10000px; // impossibly big value for animation to continue to work

.#{$dropdown} {
  &__control {
    font-size: $font-size-base;
    position: relative;
    color: $color-neutral-one;
    background-color: $color-background-item-default;
  }

  & label {
    font-size: $font-size-base;
  }

  &__toggle {
    height: 50px;
    padding-left: 11px;
    line-height: 50px;
    position: relative;
    cursor: pointer;

    outline: 1px solid $color-neutral-two;
    outline-offset: -1px;

    &:focus {
      outline: 2px solid $blueFocus;
      outline-offset: -2px;
    }

    &:before {
      content: "";
      position: absolute;
      right: 20px;
      top: calc(50% + 2.5px);
      border: 6px solid transparent;
      border-top: 10px solid $color-neutral-one;
    }

    &:after {
      content: "";
      position: absolute;
      right: 20px;
      top: calc(50% - 18.5px);
      border: 6px solid transparent;
      border-bottom: 10px solid $color-neutral-one;
    }
  }

  &__toggle-content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 52px; // avoid overflow by arrows
  }

  &__options-container {
    padding: 0;
    position: absolute;
    z-index: $dropdown-zindex;
    min-width: 100%;
    max-width: 100vw;
    background-color: $color-background-item-default;
    top: 50px;
    opacity: 0;

    display: flex;
    flex-flow: column nowrap;

    @include breakpoint(small) {
      width: 100%;
    }

    &.open {
      border: 1px solid $color-neutral-two;
      max-height: $dropdown-options-max-height;
      opacity: 1;
      transition: max-height 9s cubic-bezier(0.58, 0.56, 1, 0.52),
        opacity 0.25s ease-in;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &__options {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 0 auto;

    @include styled-scrollbar;
  }
}
