
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$c-to-top-icon-size: calculate-rem(22px);
$c-to-top-label-size: calculate-rem(12px);

.c-to-top {
  position: fixed;
  z-index: $back-to-top-zindex;
  bottom: 50px;
  display: flex;
  flex-direction: row-reverse;
  max-width: $global-width;
  left: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none;

  @include breakpoint(small) {
    bottom: 5px;
    padding-right: 5px;
  }

  @media print {
    display: none !important;
  }

  body[style*="overflow: hidden;"] & {
    display: none !important;
  }

  .g-no-scroll & {
    display: none !important;
  }

  .g-no-scroll-small & {
    @include breakpoint(small) {
      display: none !important;
    }
  }

  .g-no-scroll-medium & {
    @include breakpoint(medium) {
      display: none !important;
    }
  }

  .g-no-scroll-large & {
    @include breakpoint(large) {
      display: none !important;
    }
  }

  .g-hide-to-top & {
    display: none !important;
  }

  .g-hide-to-top-small & {
    @include breakpoint(small) {
      display: none !important;
    }
  }

  .g-hide-to-top-medium & {
    @include breakpoint(medium) {
      display: none !important;
    }
  }

  .g-hide-to-top-large & {
    @include breakpoint(large) {
      display: none !important;
    }
  }

  &__element {
    width: 50px;
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $color-background-to-top;
    color: $color-text-to-top;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    pointer-events: initial;
    transition: background-color 0.2s;

    &:hover {
      background-color: $color-brand-one;
    }

    &:active,
    &:focus {
      background-color: $color-accent-one;
      outline: 2px solid $blueFocus;
    }
  }

  &__icon {
    font-size: $c-to-top-icon-size;
    line-height: $c-to-top-icon-size;
  }

  &__label {
    font-size: $c-to-top-label-size;
    line-height: $c-to-top-label-size;
    margin-top: -2px;
  }
}
