﻿/*
/ _base.type.scss
*/

body {
  font-family: $font-primary;
  font-size: $font-size-base;
}

p
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  line-height: 1.5;
}

ul{
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}
