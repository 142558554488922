
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-showreels {
  padding-top: 35px;

  &__loading-media {
    position: relative;
    width: 100%;
  }

  @include breakpoint(medium_down) {
    padding-top: 10px;
  }

  &__add-showreel {
    border: solid 2px $color-brand-one;
    height: 130px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    @include breakpoint(medium) {
      height: 110px;
    }

    @include breakpoint(small) {
      height: 100px;
    }

    &:hover {
      background-color: rgba($color-accent-one, 0.05);
    }

    &:active {
      .c-showreels__add-label {
        text-decoration: underline;
      }
    }
  }

  &__loading {
    position: relative;
    width: 100%;

    &-thumbnail {
      height: 130px;
      position: relative;

      @include breakpoint(medium) {
        height: 110px;
      }

      @include breakpoint(small) {
        height: 100px;
      }
    }

    &-title {
      margin-top: 11px;
      width: 50%;
      height: 12px;
    }
  }

  &__add-label {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    line-height: $font-size-base;
  }

  &__add-icon {
    display: block;
    font-size: 48px;
  }

  .c-reorderable-list ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    @include breakpoint(medium) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint(small) {
      grid-template-columns: 1fr;
    }
  }

  .c-reorderable-list__item-container {
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;

    @include breakpoint(medium) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .c-showreels-list__wrapper {
    margin-left: -15px;
    margin-right: -15px;
    overflow: auto;

    @include breakpoint(medium) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
