
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-tilt-setting {
  height: 100%;

  @include breakpoint(medium_up) {
    padding: 11px 54px 0 53px;
  }

  @include breakpoint(small) {
    padding: 4px 15px 0 15px;
  }
}
