﻿/*
/ _trumps.helpers.scss
*/

// E.G. float: left, display: none, visibility: hidden, A11Y text,

@mixin visually-hidden-settings {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.visually-hidden {
  @include visually-hidden-settings;

  &--sm-up {
    @include breakpoint(small up) {
      @include visually-hidden-settings;
    }
  }

  &--md-up {
    @include breakpoint(medium up) {
      @include visually-hidden-settings;
    }
  }

  &--lg-up {
    @include breakpoint(large up) {
      @include visually-hidden-settings;
    }
  }
  &--only-md {
     @include breakpoint(medium only) {
        @include visually-hidden-settings;

     }
  }

  &--sm-down {
    @include breakpoint(small down) {
      @include visually-hidden-settings;
    }
  }

  &--md-down {
    @include breakpoint(medium down) {
      @include visually-hidden-settings;
    }
  }

  &--lg-down {
    @include breakpoint(large up) {
      @include visually-hidden-settings;
    }
  }
}

.is-hidden {
    display: none;
    visibility: hidden;
}

.is-visible {
  display: block;
  visibility: visible;
}

.is-hidden-mobile {
  display: none;
  visibility: hidden;

  @include breakpoint(large up) {
    display: block;
    visibility: visible;

    &--inline {
      display: inline;
      visibility: visible;
    }
  }
}

.is-hidden-desktop {
  @include breakpoint(large up) {
    display: none;
    visibility: hidden;
  }
}

.u-right {
  float: right;
}

.u-left {
  float: left;
}

// Spacing helpers
$spacings: (
  top: (
    props: ("padding-top"),
    mobile: $spacing-large,
    tablet: $spacing-large,
    desktop: $spacing-large
  ),
  bottom: (
    props: ("padding-bottom"),
    mobile: $spacing-large,
    tablet: $spacing-large,
    desktop: $spacing-large
  ),
  top-and-bottom: (
    props: ("padding-top", "padding-bottom"),
    mobile: $spacing-large,
    tablet: $spacing-large,
    desktop: $spacing-large
  )
);

@each $spacing, $props in $spacings {
  .u-spacing--#{$spacing} {

    $styles: map-get($props, props);

    @each $style in $styles {
      #{$style}: map-get($props, mobile);
    }

    @include breakpoint(medium up) {
      @each $style in $styles {
        #{$style}: map-get($props, tablet);
      }
    }

    @include breakpoint(large up) {
      @each $style in $styles {
        #{$style}: map-get($props, desktop);
      }
    }
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, .5, .8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  @include defaultTransition(opacity, .5s, ease);
}
.component-fade-enter,
.component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
