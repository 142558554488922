
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "./PhotoVars";

@mixin showPhotoIdOverlay {
  transform: translateY(0);
  transition: transform 0.2s;
  @include breakpoint(small) {
    transform: unset;
    transition: unset;
  }
}

@mixin gallery-item-height-view {
  height: $gallery-view-item-height;
  transition: height 0.5s;

  @include breakpoint(medium) {
    height: $gallery-view-item-height__md;
  }

  @include breakpoint(small) {
    height: $gallery-view-item-height__sm;
  }
}

@mixin gallery-item-height-edit {
  height: $gallery-edit-item-height;
  transition: height 0.5s;

  @include breakpoint(medium) {
    height: $gallery-edit-item-height__md;
  }

  @include breakpoint(small) {
    height: $gallery-edit-item-height__sm;
  }
}

.c-photo {
  display: block;
  position: relative;
  cursor: pointer;

  .c-stub {
    position: unset;
    width: 200px;

    &__logo {
      min-width: 60px;
      width: 60px;
    }
  }

  &.edit {
    @include gallery-item-height-edit;
    transition: height 0.5s;
    overflow: hidden;

    img {
      @include gallery-item-height-edit;
    }

    .c-stub {
      width: 200px;
      @include gallery-item-height-edit;
    }

    &:hover {
      & .c-photo__photo-id-overlay {
        @include showPhotoIdOverlay;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba($color-neutral-one, 0.7);
  }

  &:focus {
    box-shadow: 0 0 10px 0 rgba($color-neutral-one, 0.7);
    outline: 2px solid $blueFocus;
    z-index: 1;

    & + .c-checkbox {
      z-index: 1;
    }
    & + .c-radio {
      z-index: 1;
    }
  }

  .c-hidden-overlay {
    cursor: pointer;
  }

  &__photo-id-overlay {
    display: block;
    position: absolute;
    bottom: 0;
    color: $white;
    transform: translateY(100%);
    transition: transform 0.2s;
    font-size: 12px;
    line-height: 10px;
    background-color: rgba($shark-grey-5, 0.5);
    padding: 0;
  }
}
