
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-input-wrapper {
  .rich-text__wrapper {
      @apply mb-0;
  }

  &--invalid-error {
    .ql-container, .ql-snow, .rich-text__word-count-below {
      border-color: red;
    }
  }
}
