
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.input-array {
  position: relative;

  &__scroll-anchor {
    position: absolute;
    top: -55px;
  }

  &__add {
    display: flex;

    &__input {
      padding: 0 12px;
    }

    &__button {
      width: fit-content;
    }
  }

  &__array {
    display: flex;
    flex-direction: column;
    margin-top: 14px;

    &__item {
      min-height: 3.25rem;
      display: flex;
      align-items: center;
      background-color: $color-neutral-three;
      border: 1px solid $light-grey-2;

      &__text {
        padding: 0.68rem 0 0.68rem 0.68rem;
        word-break: break-word;
      }

      &__button {
        margin-left: auto;
        width: auto;
      }
    }
  }
}
