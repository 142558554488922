
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$play-icon-size: 47px;
$grey-gradient: #f0f0f0;

.c-audio-viewer {
  &__preview {
    border: 1px solid $medium-grey-3;
    background: $color-neutral-three;
    box-sizing: border-box;
    height: 75px;
    padding: 14px 12px;
    display: flex;
    align-items: center;

    &--loading {
      border: none;
      background: linear-gradient(to top, $color-neutral-three, $grey-gradient);
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        width: 60px;
        height: 300%;
        background: linear-gradient(
          -90deg,
          rgba($white, 0.1),
          $white,
          rgba($white, 0.1)
        );
        opacity: 0.7;
        position: absolute;
        top: -95%;
        z-index: 1;
        transform: rotate(45deg);
        animation-name: light;
        animation-duration: 3s;
        animation-delay: 0.05s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
      }
    }
  }

  &__icon {
    cursor: pointer;
    flex-shrink: 0;
    min-height: 47px;
    min-width: 49px;

    & .icon-playsmall {
      font-size: $play-icon-size;
      color: $color-brand-one;
    }

    & + .c-audio-viewer__info {
      margin-left: 11px;
      overflow: hidden;
    }

    &:hover {
      .icon-playsmall {
        color: $color-brand-two;
      }
    }

    &:focus,
    &:active {
      .icon-playsmall {
        color: $color-brand-two;
      }
    }
  }

  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-semibold;
    width: 100%;
  }

  &__duration {
    font-size: $font-size-small;
    width: 100%;
    min-height: 18px;
  }
}

@keyframes light {
  0% {
    left: -100%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
