
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-localization-dialog {
  &__language-title {
    margin-top: 32px;
    font-size: $font-size-language-title;
    font-weight: $font-weight-semibold;
  }

  &__languages {
    margin-bottom: 5px;
  }

  &__language {
    margin-top: 15px;
  }

  &__item {
    position: relative;
  }

  &__flag {
    position: absolute;
    height: 33px;
    width: 33px;
    top: -5px;
    left: -6px;
  }

  &__name {
    padding-left: 35px;
  }

  .c-select__selected &__flag {
    top: 8px;
    left: -3px;
  }
}
