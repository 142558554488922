
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import '../Photo/PhotoVars';

.c-photoset {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  &__error {
    height: 150px;
    width: 200px;
    position: relative;
  }

  &__stub {
    .c-stub__logo {
      width: 60px;
      margin-top: -50px;
      min-width: unset;
    }
  }

  &__checkbox {
    position: absolute;
    top: 10px;
    left: 10px;

    @include breakpoint(small) {
      top: unset;
      left: unset;
      bottom: 10px;
      right: 10px;
    }
  }

  &__image-container {
    margin-left: 1px;
    margin-bottom: 1px;
    position: relative;
    list-style: none;

    .c-radio {
      position: absolute;
      top: 10px;
      left: 10px;

      @include breakpoint(small) {
        top: unset;
        left: unset;
        bottom: 10px;
        right: 10px;
      }

      label {
        display: block;
        width: 35px;
        height: 35px;

        @include breakpoint(small) {
          width: 44px;
          height: 44px;
        }

        .c-radio__box {
          position: absolute;
          bottom: 4px;
          right: 4px;
          margin: 0;
          border-radius: 0;

          &:before {
            border-radius: 0;
            box-shadow: 0 0 0 3px $color-background-checkbox-default;
            left: 3px;
            right: 3px;
            top: 3px;
            bottom: 3px;
          }

          @include breakpoint(small) {
            bottom: unset;
            right: unset;
            top: 11px;
            left: 11px;
          }
        }
      }
    }
  }

  .c-add-photo {
    margin-left: 1px;
    margin-bottom: 1px;

    &__panel {
      display: flex;
      justify-content: center;

      @include breakpoint(large) {
        height: $gallery-edit-item-height;
        width: 140px;
        animation: gallery-add-photo-bump 0.5s;
      }

      @include breakpoint(medium) {
        height: $gallery-edit-item-height__md;
        width: 120px;
        animation: gallery-add-photo-bump__md 0.5s;
      }

      @include breakpoint(small) {
        height: $gallery-edit-item-height__sm;
        width: 100px;
        animation: gallery-add-photo-bump__sm 0.5s;
      }
    }
  }

  &.single-select {
    .c-add-photo__panel {
      @include breakpoint(large) {
        height: $gallery-view-item-height;
        width: 130px;
        animation: unset;
      }

      @include breakpoint(medium) {
        height: $gallery-view-item-height__md;
        width: 110px;
        animation: unset;
      }

      @include breakpoint(small) {
        height: $gallery-view-item-height__sm;
        width: 100px;
        animation: unset;

        & .icon-plus {
          font-size: 40px;
        }
      }

      &-text {
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }
}

@keyframes gallery-add-photo-bump {
  0% {
    height: $gallery-view-item-height;
  }
  100% {
    height: $gallery-edit-item-height;
  }
}
@keyframes gallery-add-photo-bump__md {
  0% {
    height: $gallery-view-item-height__md;
  }
  100% {
    height: $gallery-edit-item-height__md;
  }
}
@keyframes gallery-add-photo-bump__sm {
  0% {
    height: $gallery-view-item-height__sm;
  }
  100% {
    height: $gallery-edit-item-height__sm;
  }
}
