
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-control-section {
  padding-top: 30px;
  position: relative;
  border-bottom: 1px solid rgba($color-neutral-one, 0.25);

  &__section-name {
    font-weight: $font-weight-semibold;
    line-height: 27px;
    @apply mb-5;
  }

  &__section-description {
    padding: 10px 0;
  }

  &__section-action {
    position: absolute;
    right: 20px;
    top: 8px;
  }
}
