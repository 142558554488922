
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "../../../../src/patternlib-styles/trumps/_trumps.helpers";
@import "../../../../src/patternlib-styles/base/_base.links";
@import "../../../../src/patternlib-styles/base/_base.page";
@import "../../../../src/patternlib-styles/base/_base.type";

.c-social-list {
  margin: 0 auto;
  width: calculate-rem(200px);
  text-align: center;
  font-family: $font-primary;
  direction: ltr;

  a {
    color: $color-text-on-dark-background;
  }

  &__title {
    display: block;
    margin-bottom: $spacing-medium;
    color: $color-text-footer;
    font-size: $font-size-medium;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    padding: 0;
    width: 206px;
    list-style: none;
  }

  &__link {
    display: inline-grid;
    padding: 10px;
    height: calculate-rem(45px);
    width: calculate-rem(45px);
    max-width: calculate-rem(45px);
    max-height: calculate-rem(45px);
    background-color: $white;
    border-radius: 50%;

    @include defaultTransition(background-color);

    &:focus,
    &:hover {
      background-color: $color-neutral-two;
    }
  }
}

.c-social-list__icon {
  height: 25px !important;
  width: 25px !important;
  fill: $color-social-icon;
}
