
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-subnavigation-item {
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: $color-background-subnavigationitem-hover;
  }
}
.c-subnavigation-item__link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: $font-size-tiny;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $color-background-subnavigationitem;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  padding-right: 20px;
  padding-left: 70px;

  &:focus {
    outline: 2px solid $blueFocus;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  &.selected {
    text-decoration: underline;
  }
}
