
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$play-icon-size: 47px;
$grey-gradient: #f0f0f0;

.c-thumbnail-video-viewer {
  &__cover {
    position: relative;
    display: inline-block;
    cursor: pointer;

    & .icon-playsmall {
      position: absolute;
      font-size: $play-icon-size;
      left: calc(50% - #{$play-icon-size / 2});
      top: calc(50% - #{$play-icon-size / 2});
      text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      color: $white;
      opacity: 0.8;
    }

    &:hover {
      & .icon-playsmall {
        color: $color-brand-one;
      }
    }

    &:active,
    &:focus {
      & .icon-playsmall {
        color: $color-accent-one;
      }
    }
  }

  &__loading {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 5px;

    &-cover {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    & .c-shimmering-stub:after {
      top: -130px;
    }
  }

  &__title {
    margin-top: -5px;
    font-size: 16px;
    font-weight: $font-weight-semibold;
  }

  &__duration {
    font-size: 14px;
  }

  &__popup {
    .c-video__video {
      @include breakpoint(medium_up) {
        height: auto;
        max-height: 75vh;
        margin: 0 auto;
      }
    }
  }
}
