
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.ADD-ON-subscription-card {
  @apply rounded-[10px] opacity-90 border-grey-medium-3 border-2 items-center flex justify-center min-h-[302px] h-full md:w-[50%];

  &--selected {
    @apply text-white border-color-brand-two bg-color-brand-two;
  }

  &__inner {
    @apply h-full px-3 py-5 gap-4 flex flex-col min-w-[300px] md:min-h-[302px] md:justify-between w-full sm:w-auto;
  }

  .block {
    @apply flex flex-col justify-around items-center h-full flex-1;

    p {
      @apply leading-[22px];
    }

    &__details {
      @apply flex items-center gap-1 align-middle;
    }

    &__header {
      @apply text-[1.78rem] font-semibold;
    }

    &__deal-badge {
      @apply p-1 text-sm font-semibold rounded text-color-neutral-one bg-color-accent-one;
    }

    &__price {
      @apply font-bold text-[1.78rem] text-color-neutral-one;

      &--selected {
        @apply text-white;
      }
    }

    &__text {
      @apply text-color-neutral-one mt-5 text-center;

      &--selected {
        @apply text-white;
      }
    }
  }

  .selected-button {
    @apply text-color-brand-two bg-blue-sky-2 border-color-brand-two;
  }
}
