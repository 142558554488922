
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-photo-tool {
  position: relative;

  &__cropper-validation {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: $cardinal-red;
    padding: 0 13px 0 40px;
    color: $white;
    font-size: 16px;
    line-height: 40px;

    &::before {
      content: "!";
      position: absolute;
      top: 50%;
      left: 4px;
      background-color: $color-brand-one;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-top: -12px;
      text-align: center;
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__tool-settings {
    background-color: $sky-blue-2;

    @include breakpoint(medium_up) {
      height: 84px;
    }

    @include breakpoint(small) {
      height: 55px;
    }
  }

  &__image {
    position: relative;
    min-height: 200px;
  }

  &__tool-bar {
    @include breakpoint(medium_up) {
      height: 120px;
    }
    @include breakpoint(small) {
      height: 71px;
    }
  }
}
