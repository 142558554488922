
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-filter-drawer-item {
  $c: c-filter-drawer-item;
  $filter-drawer-control-size: 50px;
  background: $color-neutral-three;
  margin-bottom: 10px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    .#{$c}__icon-font {
      color: $color-neutral-one;
    }
  }

  &:focus {
    outline: 2px solid $blueFocus;
  }

  &__icon-font {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $filter-drawer-control-size;
    line-height: $filter-drawer-control-size;
    flex-basis: $filter-drawer-control-size;
    min-width: $filter-drawer-control-size;
    color: $color-brand-one;
    text-align: center;
  }

  &__title {
    flex-grow: 1;
    overflow: hidden;
    margin: 0 15px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--selected {
      font-weight: $font-weight-semibold;
    }
  }
}
