
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-full-screen-spinner {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($white, 0.55);
}
