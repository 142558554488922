
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-audio-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid $light-grey-2;
  width: 100%;
  height: 75px;
  color: $color-neutral-one;
  background-color: $color-neutral-three;
  margin-bottom: 10px;

  &__info {
    width: 100%;
    overflow: hidden;
    padding-right: 10px;

    &-title {
      font-size: $font-size-medium;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &-duration {
      font-size: $font-size-small;
    }
  }

  &__checkbox {
    display: block;
    margin-right: 10px;

    & .c-checkbox__box {
      background-color: $white;
    }
  }

  &__status {
    min-width: 66px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    &-text {
      font-size: $font-size-tiny;
      font-weight: $font-weight-semibold;
    }

    &-icon {
      font-size: 38px;
    }
  }

  &--complete,
  &--hidden {
    .c-audio-item__status {
      cursor: pointer;
    }

    .c-audio-item__info {
      &:hover {
        cursor: pointer;

        .c-audio-item__info-title,
        .c-audio-item__info-duration {
          text-decoration: underline;
        }
      }

      &:focus,
      &:active {
        .c-audio-item__info-duration {
          text-decoration: none;
        }
      }
    }
  }

  &--complete {
    .c-audio-item__status {
      color: $color-brand-one;

      &-icon {
        font-size: 47px;
      }

      &:hover {
        color: $color-brand-two;
      }

      &:focus,
      &:active {
        color: $color-brand-two;
      }
    }
  }

  &--loading {
    border: none;

    .c-audio-item__info {
      padding-top: 19px;
      padding-left: 67px;
    }
  }
}
