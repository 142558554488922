
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.capacity-bar-container {
  display: flex;
  border: 1px solid $light-grey-2;

  @include breakpoint(small) {
    display: grid;
  }
}
