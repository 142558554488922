
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$video-component: c-video;
$closeButtonSequence: 1;

.#{$video-component} {
  position: relative;
  background-color: $color-neutral-one;
  text-align: center;
  height: 100%;
  min-height: 50vh;

  &:hover,
  &:focus-within {
    .#{$video-component}__close-btn,
    .#{$video-component}-controls,
    .#{$video-component}__file-name {
      opacity: 1;
    }
  }

  &__title-prefix {
    text-transform: uppercase;
  }

  &__file-name {
    text-shadow: 0 1px 2px $color-neutral-one;
    font-family: $font-body;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    color: $white;
    margin: 5px;
    display: inline-block;
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease;

    @include breakpoint(small) {
      opacity: 1;
    }
  }

  &__btn {
    @include removeDefaultButtonStyling;
    cursor: pointer;
    position: absolute;
    top: 0;

    &:before {
      color: $white;
      font-size: $font-size-icon-default;
    }

    &-disabled {
      pointer-events: none;
    }

    &:focus {
      outline: 2px solid $blueFocus;
      border-radius: 3px;
    }
  }

  &__close-btn {
    padding: 12px 6px 12px 12px;
    display: inline-block;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
    will-change: opacity;
    z-index: $closeButtonSequence;
    outline: none;

    @include breakpoint(small) {
      opacity: 1;
    }

    svg {
      @include breakpoint(small) {
        width: 17px;
        height: 17px;
      }
    }
  }

  &__cover-pause-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
    outline: none;

    &:before {
      font-size: 47px;
    }

    &:hover {
      &:before {
        color: $color-brand-one;
      }
    }

    &:active,
    &:focus {
      &:before {
        color: $light-grey-2;
      }
    }
  }

  &__video {
    max-width: 100%;
    vertical-align: middle;
    height: 100%;
  }

  &__share-link-wrapper {
    display: flex;
  }

  &__copy-share-link-input {
    width: calc(100% - 50px);

    .c-input-wrapper {
      margin-bottom: 18px;
    }
  }

  &__copy-share-link-button {
    width: 50px;
    margin: 18px 0;
  }

  &__main-share-popup-description,
  &__additional-share-popup-description {
    margin: 0;
  }
}
