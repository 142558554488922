
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-capacity-bar {
  font-family: $font-body;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  color: $color-neutral-one;
  margin: 5px 0;
  display: flex;
  flex: 1 100%;

  + .c-capacity-bar {
    border-left: 1px solid $light-grey-2;

    @include breakpoint(small) {
      border-left: none;
      border-top: 1px solid $light-grey-2;
    }
  }

  @include breakpoint(small) {
    margin: 0 10px;
    padding: 5px 0;
  }

  &__label-container {
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include breakpoint(small) {
      padding: 0;
    }
  }

  &__icon {
    font-size: 40px;
    color: $color-brand-two;

    @include breakpoint(small) {
      display: none;
    }

    &--small-screen {
      display: none;
      font-size: $font-size-icon-default;
      margin-right: 10px;

      @include breakpoint(small) {
        display: inline-block;
      }
    }
  }

  &__info-container {
    width: 100%;
  }

  &__info-label {
    p {
      display: inline;
      font-size: $font-size-small;
    }

    &--loading {
      height: 21px;
    }
  }
}
