
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-video-showreel {
  display: flex;
  height: fit-content;
  background-color: $color-neutral-one;
  position: relative;
  align-items: stretch;

  &__scrolling-block {
    display: flex;
    white-space: nowrap;
    overflow: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__control {
    cursor: pointer;
    background-color: $color-neutral-one;
    width: 32px;
    z-index: 1;

    &:before {
      color: $color-brand-one;
      font-size: 24px;
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      transition: color 0.2s ease-in-out;
    }

    &:hover:before {
      color: $color-accent-one;
    }
  }

  &__right-control {
    right: 0;
  }

  @include breakpoint(small) {
    display: none;
  }
}
