
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$audio-font-color: $white;
$audio-track-control-bar-bg: $color-neutral-one;
$audio-track-font-size: $font-size-small;

.c-audio-controls {
  background-color: rgba($audio-track-control-bar-bg, 0.6);
  height: 36px;
  display: flex;
  align-items: center;
  color: $audio-font-color;
  font-size: $audio-track-font-size;
  padding: 0 4px 0 9px;

  &__time {
    width: 100%;
    margin-left: 14px;
  }

  &__loading {
    margin-left: 12px;
  }
}
