
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-page-404 {
  background: #090506
    url(https://www.spotlight.com/Features/Sunlight/Error/404.jpg) fixed
    no-repeat bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__logo {
    display: block;
    width: 320px;
    margin: 0 auto;
    padding-top: 50px;
  }

  &__header {
    font-family: $font-secondary, Sans-Serif;
    font-size: 120px;
    font-weight: normal;
    text-align: center;
    line-height: 1.4;
    color: $white;
    margin: 10px 0 0;
  }

  &__message {
    font-family: $font-body;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: $white;
    margin-top: 10px;
  }

  &__button-container {
    display: flex;
    padding-bottom: 10px;
  }

  &__button {
    font-family: $font-body;
    margin: 30px auto 0;
    width: auto;
  }
}
