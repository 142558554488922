
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
body {
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}

.t-setting__full-width-sm {
  & > .c-popup__wrapper {
    @include breakpoint(small) {
      padding: 0;
    }
  }
}

.t-setting__tooltip-right-sm {
  &.c-tooltip {
    @include breakpoint(small) {
      .c-tooltip__container {
        left: 0;
      }
      .c-tooltip__pointer {
        left: 5px;
      }
    }
  }
}
.t-setting__tooltip-right-md {
  &.c-tooltip {
    @include breakpoint(medium) {
      .c-tooltip__container {
        left: 0;
      }
      .c-tooltip__pointer {
        left: 5px;
      }
    }
  }
}

.t-setting__tooltip-right-lg {
  &.c-tooltip {
    @include breakpoint(large) {
      .c-tooltip__container {
        left: 0;
      }
      .c-tooltip__pointer {
        left: 5px;
      }
    }
  }
}

.spinner-bg {
  z-index: 10000;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba($white, 0.55);
}

@page {
  size: A4;
  margin: 11mm 0;
}

@media print {
  html,
  body {
    width: 210mm;
  }

  .c-navigation-header__menu-container {
    display: none;
  }

  .zendesk-container {
    display: none;
  }

  * {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .c-navigation-header {
    display: none;
  }

  .a-master-page__footer {
    display: none;
  }

  #app {
    height: unset;
  }
}
