
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$spinner: c-spinner;

.#{$spinner} {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  will-change: transform;

  &--inline {
    position: static;
    height: auto;
    width: auto;
  }

  &__icon {
    margin: auto;
    width: 167px;
  }

  &__icon--secondary {
    width: 40%;
    max-width: 167px;
    path {
      fill: $white;
    }
  }
}

body.g-prevent-spinner-animation .#{$spinner} .#{$spinner}__icon {
  animation: none !important;
}
