/*
/ _settings.fonts.scss
*/

// Font Stacks
$fonts-url: #{"https://staticassets.spotlight.com/fonts/"};
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700;1,800&display=swap");
// Set @font-face declarations here
// Font Stacks

$font-body: "Wix Madefor Text", "Trebuchet", sans-serif;
$font-weight-regular: 400;
$font-weight-buttons-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-primary: $font-body;
$font-secondary: "Futura-PT", "Gill Sans", sans-serif;

$font-size-xx-large: 30px;
$font-size-x-large: 26px;
$font-size-large: 22px;
$font-size-base: 16px;
$font-size-medium: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;
$font-size-AA-large: 18.66px;

$font-size-icon-default: 30px;
$font-size-icon-button-tertiary: 25px;

//Titles
$font-size-language-title: $font-size-base;
$font-size-card-name: $font-size-small;
$font-size-card-member-type: $font-size-tiny;

//Font weights
$font-weight-card-name: $font-weight-semibold;
