
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-step-tracker {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  pointer-events: none;

  @include breakpoint(small) {
    height: auto !important;
  }

  &__container {
    border-bottom: 1px solid $light-grey-2;
    background-color: $white;
    pointer-events: auto;
  }

  &__inner {
    max-width: 1200px;
    padding: 5px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    @include breakpoint(small) {
      padding: 0;
    }
  }

  &__item-wrapper {
    width: 20%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
  }
}
