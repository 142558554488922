
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-new-audio-form {
  padding: 0;

  &__description {
    margin-bottom: 30px;
  }

  &__control {
    padding: 0;
    margin-bottom: $input-margin-bottom;

    @include breakpoint(small) {
      width: 100%;
    }
  }
}
