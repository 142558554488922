
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.direct-debit-confirm {
  .header {
    @apply text-[26px] leading-[39px] text-center font-semibold;
    @apply border-y-grey-light-2 border-y border-solid;
    @apply py-5 mt-5 mb-[85px];
  }

  .data-block {
    @apply border-x border-b border-grey-fog-1 rounded-b-[15px] p-[30px];

    .data-row {
      @apply text-base leading-[21px] mb-[30px];
      .name {
        @apply mb-[10px];
      }
      .value {
        @apply font-semibold;
      }
    }
  }

  .edit-button {
    @apply flex-row-reverse border-0 text-color-brand-two shadow-none h-[25px] w-[78px];
  }

  .submit-button {
    @apply flex-row-reverse bg-color-brand-two border-color-brand-two max-w-[316px] rounded-[25px] mb-[60px];

    :hover,
    :focus {
      @apply bg-color-brand-two border-color-brand-two;
    }
  }

  .security-block {
    @apply flex flex-col items-center bg-grey-fog-1;
    @apply rounded-[15px] max-w-[538px] w-[100%] mb-[140px] px-[30px] pt-[15px] pb-[40px];

    > a {
      @apply text-[14px] mb-[10px];
    }

    > p {
      @apply text-xs text-color-neutral-one mb-5 text-center;
    }

    .legal {
      > a {
        @apply text-color-neutral-one font-normal;
      }
    }
  }
}
