
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-reorderable-list {
  ul,
  &__item-container {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item-container {
    user-select: none;

    &.x, &.xy {
      display:inline-block;
    }
  }
}

.animated-helper {
  transition: none !important;
}
