@font-face {
  font-family: "spotlighticon";
  src: url(#{$fonts-url + 'spotlighticon/spotlighticon.eot?adnd67'});
  src: url(#{$fonts-url + 'spotlighticon/spotlighticon.eot?adnd67#iefix'})
      format("embedded-opentype"),
    url(#{$fonts-url + 'spotlighticon/spotlighticon.ttf?adnd67'})
      format("truetype"),
    url(#{$fonts-url + 'spotlighticon/spotlighticon.woff?adnd67'})
      format("woff"),
    url(#{$fonts-url + 'spotlighticon/spotlighticon.svg?adnd67#spotlighticon'})
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.g-font-icon-spotlighticon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "spotlighticon" !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "spotlighticon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Large-Grid:before {
  content: "\e92a";
}
.icon-Small-grid:before {
  content: "\e958";
}
.icon-profile:before {
  content: "\e965";
}
.icon-retry:before {
  content: "\e966";
}
.icon-open-in-new-tab:before {
  content: "\e967";
}
.icon-filmcamera:before {
  content: "\e968";
}
.icon-controlsPIP:before {
  content: "\e969";
}
.icon-Expand:before {
  content: "\e911";
}
.icon-Filter:before {
  content: "\e90c";
}
.icon-Print:before {
  content: "\e938";
}
.icon-alarm:before {
  content: "\e922";
}
.icon-arrowdown:before {
  content: "\e900";
}
.icon-arrowup:before {
  content: "\e901";
}
.icon-arrowleft:before {
  content: "\e963";
}
.icon-arrowright:before {
  content: "\e964";
}
.icon-audio:before {
  content: "\e921";
}
.icon-backtotoparrow:before {
  content: "\e920";
}
.icon-basket:before {
  content: "\e91c";
}
.icon-bell:before {
  content: "\e944";
}
.icon-book:before {
  content: "\e923";
}
.icon-bottom:before {
  content: "\e924";
}
.icon-brightness:before {
  content: "\e91b";
}
.icon-bubble1:before {
  content: "\e945";
}
.icon-burger:before {
  content: "\e90d";
}
.icon-camera1:before {
  content: "\e959";
}
.icon-check-circle:before {
  content: "\e946";
}
.icon-check1:before {
  content: "\e947";
}
.icon-chevron:before {
  content: "\e90b";
}
.icon-chevroncircle:before {
  content: "\e90f";
}
.icon-chevrondown:before {
  content: "\e925";
}
.icon-chevronleft:before {
  content: "\e926";
}
.icon-chevronright:before {
  content: "\e927";
}
.icon-chevronup:before {
  content: "\e928";
}
.icon-circledot:before {
  content: "\e92b";
}
.icon-clients:before {
  content: "\e929";
}
.icon-cog1:before {
  content: "\e948";
}
.icon-contacts:before {
  content: "\e91d";
}
.icon-contrast:before {
  content: "\e902";
}
.icon-crop:before {
  content: "\e903";
}
.icon-cross1:before {
  content: "\e96a";
}
.icon-cross:before {
  content: "\e96a";
}
.icon-cursor:before {
  content: "\e942";
}
.icon-delete:before {
  content: "\e904";
}
.icon-doc-plus:before {
  content: "\e949";
}
.icon-download1:before {
  content: "\e94a";
}
.icon-drag:before {
  content: "\e94b";
}
.icon-copylink:before {
  content: "\e96b";
}
.icon-role:before {
  content: "\e96c";
}
.icon-duplicate:before {
  content: "\e986";
}
.icon-edit:before {
  content: "\e906";
}
.icon-events:before {
  content: "\e91e";
}
.icon-expandimg:before {
  content: "\e915";
}
.icon-eye-red:before {
  content: "\e912";
}
.icon-eye-red1:before {
  content: "\e92c";
}
.icon-eyeclosed:before {
  content: "\e92d";
}
.icon-faqs:before {
  content: "\e92e";
}
.icon-filter:before {
  content: "\e92f";
}
.icon-folder:before {
  content: "\e930";
}
.icon-heart:before {
  content: "\e94c";
}
.icon-hide:before {
  content: "\e905";
}
.icon-home1:before {
  content: "\e94d";
}
.icon-lhnhelp:before {
  content: "\e931";
}
.icon-list:before {
  content: "\e987";
}
.icon-lock1:before {
  content: "\e94e";
}
.icon-magnify:before {
  content: "\e932";
}
.icon-map-pointer:before {
  content: "\e94f";
}
.icon-map1:before {
  content: "\e950";
}
.icon-messages:before {
  content: "\e933";
}
.icon-minus:before {
  content: "\e914";
}
.icon-move-arrow:before {
  content: "\e913";
}
.icon-move1:before {
  content: "\e951";
}
.icon-notes:before {
  content: "\e934";
}
.icon-nudge:before {
  content: "\e988";
}
.icon-paperclip1:before {
  content: "\e952";
}
.icon-pen:before {
  content: "\e935";
}
.icon-pencil:before {
  content: "\e936";
}
.icon-performancecal:before {
  content: "\e937";
}
.icon-phone-landline1:before {
  content: "\e953";
}
.icon-phone-mobile1:before {
  content: "\e954";
}
.icon-picture1:before {
  content: "\e955";
}
.icon-playsmall:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e91a";
}
.icon-processing:before {
  content: "\e939";
}
.icon-replace:before {
  content: "\e907";
}
.icon-rotate:before {
  content: "\e908";
}
.icon-send:before {
  content: "\e989";
}
.icon-settings:before {
  content: "\e93a";
}
.icon-share:before {
  content: "\e91f";
}
.icon-sharearrow:before {
  content: "\e93b";
}
.icon-shortlist:before {
  content: "\e93c";
}
.icon-showreel:before {
  content: "\e93d";
}
.icon-socialfacebook:before {
  content: "\e916";
}
.icon-socialinsta:before {
  content: "\e917";
}
.icon-socialtwitter:before {
  content: "\e918";
}
.icon-straighten:before {
  content: "\e909";
}
.icon-sun:before {
  content: "\e93e";
}
.icon-sync1:before {
  content: "\e956";
}
.icon-table:before {
  content: "\e93f";
}
.icon-top:before {
  content: "\e940";
}
.icon-twoclients:before {
  content: "\e941";
}
.icon-undo:before {
  content: "\e90a";
}
.icon-upload:before {
  content: "\e98b";
}
.icon-up-down:before {
  content: "\e98a";
}
.icon-wallet:before {
  content: "\e943";
}
.icon-website:before {
  content: "\e919";
}
.icon-zip1:before {
  content: "\e957";
}
.icon-website2:before {
  content: "\e95a";
}
.icon-controlsPause:before {
  content: "\e95b";
}
.icon-controlsPlay:before {
  content: "\e95c";
}
.icon-controlsSkip:before {
  content: "\e95d";
}
.icon-controlsMute:before {
  content: "\e95e";
}
.icon-controlsvolDown:before {
  content: "\e95f";
}
.icon-controlsvolUp:before {
  content: "\e960";
}
.icon-cross-circle:before {
  content: "\e961";
}
.icon-controlsMuteoff:before {
  content: "\e962";
}
