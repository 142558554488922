
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.p-edit-video-page {
  &__container {
    padding: 20px 0 50px;
  }

  &__content {
    padding-top: 10px;
  }

  &.g-content-area {
    @include breakpoint(small) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__buttons {
    padding: 20px 0;
    margin: 0 10px;
    display: flex;
    align-items: center;
    flex-flow: row-reverse;
    border-top: 1px solid rgba($color-neutral-one, 0.25);

    @include breakpoint(small) {
      flex-flow: column;
      margin: 0 15px;
    }
  }

  &__button {
    @include breakpoint(medium_up) {
      margin-left: 20px;
      min-width: 320px;
    }

    @include breakpoint(small) {
      width: 100%;
      margin-top: 10px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .g-tertiary-link {
    @include secondary-link;
  }
}
