
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "../../Atoms/FilterTag/FilterTag";
$max-items-count-medium: 3;
$max-items-count: 5;
$filter-row-item-class: ".c-filter-control__filter-row-item";
$filter-row-view-all: ".c-filter-control__filter-row-view-all";
$filter-row-item-margin-right: 10px;
$filter-row-height: 70px;
$filter-row-height-small: 50px;
$clear-all-button-section-width: 160px;

:export {
  buttonSectionWidth: strip-unit($clear-all-button-section-width);
}

@mixin filter-row-small {
  display: inline-block;
  height: auto;

  & #{$filter-row-view-all} > .c-icon-text-link {
    border: none;
    padding: 0;
    margin-right: 15px;
    justify-content: left;
    background: none;
  }

  & #{$filter-row-item-class} {
    display: none;
  }

  & #{$filter-row-view-all} {
    display: inline-flex;
  }
}

@mixin filter-row-medium {
  @include grid-content-area();
  grid-template-columns: repeat(12, 1fr);

  & #{$filter-row-item-class} {
    grid-column: span 3;
    width: unset;
    margin-right: 0;
  }

  & #{$filter-row-item-class}:nth-child(n + #{$max-items-count-medium + 1}) {
    display: none;
  }

  & #{$filter-row-view-all} {
    grid-column-start: 10;
    grid-column-end: span 3;
  }
}

@mixin filter-row-large {
  display: flex;

  & #{$filter-row-item-class} {
    margin-right: $filter-row-item-margin-right;
  }

  & #{$filter-row-item-class},
  & #{$filter-row-view-all} {
    max-width: calc(100% /#{$max-items-count} - 8px);
    flex-basis: calc(100% /#{$max-items-count} - 8px);
  }
}

.c-filter-control {
  @include breakpoint(small) {
    .g-content-area {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 5px;
      padding-top: 5px;
      min-height: $filter-row-height-small;
    }
  }

  &__filter-row {
    height: $filter-row-height;
    align-items: center;

    @include breakpoint(small) {
      @include filter-row-small;
    }

    @include breakpoint(medium) {
      @include filter-row-medium;
    }

    @include breakpoint(large) {
      @include filter-row-large;
    }

    // depending on items count it may have medium layout even for large device
    &--medium {
      @include filter-row-medium;

      @include breakpoint(small) {
        @include filter-row-small;
      }

      @include breakpoint(large) {
        & #{$filter-row-item-class},
        & #{$filter-row-view-all} {
          max-width: none;
        }
      }
    }

    .c-input-wrapper {
      margin-bottom: 0;

      .c-input-label {
        margin-bottom: 0;
      }
    }
  }
}
