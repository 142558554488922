
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.heritage-and-ethnicity-filter-section {
  .c-filter-mode__text {
    @apply text-s;
  }

  .c-checkbox-group__container {
    @apply mb-0;
  }

  .further-information-search {
    .c-input-label__label {
      @apply font-semibold;
    }
  }
}
