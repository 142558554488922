
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-filter-mode {
  margin: -20px -20px 20px;
  padding: 6px 20px 7px;
  background-color: $color-neutral-three;

  @include breakpoint(small) {
    margin: -15px -15px 20px;
    padding: 6px 15px 7px;
    font-size: $font-size-medium;
  }

  &__text {
    display: inline;
    vertical-align: middle;
  }

  &__selector {
    display: inline;
    margin: 0 4px;
  }
}
