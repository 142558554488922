
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-file-uploader {
  &__dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    border: dashed 1px $light-grey-2;
    color: $color-brand-one;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    background-color: $color-neutral-three;

    &--error {
      border: 1px solid $color-input-validation-error-border;
    }
  }

  &__loading {
    height: 70px;
    border: dashed 1px $light-grey-2;
  }

  &__text {
    @include breakpoint(small) {
      display: none;
    }
  }

  &__mobile-text {
    display: none;

    @include breakpoint(small) {
      display: block;
    }
  }

  &__files {
    ul {
      @include removeDefaultListStyling;
    }

    &--error {
      border: 1px solid $color-input-validation-error-border;
    }

    li {
      border-bottom: dashed 1px $light-grey-2;

      &:first-child {
        border-top: dashed 1px $light-grey-2;
      }
    }
  }

  &__dropzone + &__files ul {
    margin-top: 20px;
  }
}

.g-bg-secondary,
.c-input-group--secondary {
  .c-file-uploader {
    &__dropzone {
      background-color: $white;
    }
  }
}
