﻿/*
/ _base.page.scss
*/

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  background-color: $color-background-body;
  color: $color-text-default;
}

img {
  max-width: 100%;
}
