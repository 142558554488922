
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-name-bar {
  background-color: $color-neutral-one;
  color: $white;
  cursor: default;

  @include breakpoint(medium_up) {
    min-height: 95px;
  }

  @include breakpoint(small) {
    min-height: 70px;
  }

  &--with-action-section {
    .c-name-bar__user-container {
      @include breakpoint(medium) {
        grid-column: span 7 !important;
      }
    }
  }

  &__container {
    max-width: $global-width;
    margin: auto;
    position: relative;
  }

  &__user-container {
    padding: 10px 0;

    @include breakpoint(small) {
      padding-right: 60px;
    }
  }

  &__full-name {
    margin-right: 10px;
    text-decoration: none;
    color: inherit;
    line-height: 1.25;
    cursor: default;

    &:focus {
      outline: 2px solid $blueFocus;
    }

    @include breakpoint(medium_up) {
      font-size: 32px;
    }

    @include breakpoint(small) {
      font-size: $font-size-large;
    }
  }

  &__user-type {
    margin-top: 5px;
    font-size: $font-size-base;
    line-height: 1.5;

    @include breakpoint(small) {
      min-height: 18px;
      line-height: 1.25;
    }
  }

  &__secondary-action-with-margin {
    margin-top: 5px;
  }

  &__logo {
    position: absolute;
    top: 10px;
    right: 15px;
    min-width: 73px;
    height: 70px;
    background-size: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;

    @include breakpoint(small) {
      min-width: 51px;
      height: 49px;
    }

    &--spotlight {
      background-image: url(https://staticassets.spotlight.com/spotlight-logo.svg);
    }

    &--filmmakers {
      @include breakpoint(small) {
        width: 100px;
      }
    }
  }

  &__actions {
    @include breakpoint(medium) {
      grid-column: span 5 !important;
    }

    @include breakpoint(medium_up) {
      display: flex;
      align-items: center;
      padding: 5px 90px 5px 0;
    }

    @include breakpoint(small) {
      margin-top: -5px;
      padding-bottom: 10px;
    }
  }

  &__edit-button {
    height: 30px;
    width: 30px;
    position: relative;
    top: 2px;
    display: inline-block;
    font-size: $font-size-icon-default;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $color-brand-one;
    }

    &:focus {
      color: $color-brand-one;
      outline: 0;
    }

    @include breakpoint(small) {
      margin: -10px 0;
      top: 7px;
    }
  }
}
