
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-add-photo {
  text-align: left;

  &__panel {
    display: flex;
    justify-content: center;

    background: $color-background-button-secondary;
    border: 2px solid;
    border-color: $color-border-button-secondary;
    color: $color-text-button-secondary;
    min-height: 100%;

    height: 200px;
    width: 140px;

    @include breakpoint(medium) {
      height: 150px;
      width: 120px;
    }

    @include breakpoint(small) {
      height: 120px;
      width: 100px;
    }

    &:hover {
      background: $color-background-button-secondary-hover;
      border-color: $color-border-button-secondary-hover;
      color: $color-text-button-secondary-hover;
    }

    &:focus &-text {
      text-decoration: underline;
      outline: 0;
      outline: 2px solid $blueFocus;
    }

    &-inner {
      align-self: center;
    }

    &-text {
      font-weight: $font-weight-semibold;
      @include breakpoint(small) {
        font-size: $font-size-tiny;
      }
    }

    .icon-plus {
      font-size: 50px;

      @include breakpoint(small) {
        font-size: 25px;
      }
    }
  }
}
