
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-reorderable-media-assets {
  padding-top: 35px;

  &--loading {
    height: 75px;
  }

  @include breakpoint(medium_down) {
    padding-top: 10px;
  }

  &__add-new-item {
    border: solid 2px $color-brand-one;
    height: 75px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background-color: rgba($color-accent-one, 0.05);
    }

    &:active {
      .c-reorderable-media-assets__add-label {
        text-decoration: underline;
      }
    }

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__add-label {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    line-height: $font-size-base;
  }

  &__add-icon {
    display: block;
    font-size: 48px;
  }

  .c-reorderable-list ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint(medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(small) {
      grid-template-columns: 1fr;
    }
  }

  .c-reorderable-list__item-container {
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;

    @include breakpoint(medium) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .c-media-assets__wrapper {
    overflow: auto;
    margin-left: -15px;
    margin-right: -15px;

    @include breakpoint(medium) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
