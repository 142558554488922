
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-admin-photo-edit {
  display: flex;
  flex-flow: wrap;
  padding: 20px;
  position: relative;

  &__header {
    width: 100%;
    font-size: 26px;
    line-height: 55px;
  }

  &__photo-editor {
    width: 50%;
    height: calc(100vh - 225px);
  }

  &__photographer-name {
    position: relative;
  }

  &__photo-form {
    width: 50%;
    height: calc(100vh - 225px);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }

  &__photo-data {
    border-bottom: 1px solid $color-neutral-two;
  }

  &__buttons {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $color-brand-two;
  }

  &__button {
    margin-right: 20px;
    min-width: 210px;
  }
}
