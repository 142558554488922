
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-cleaner {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 45px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: $color-text-default;

  &:focus {
    outline: none;
  }

  &__container {
    position: relative;
  }
}
