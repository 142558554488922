
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-dropdown-tag {
  $c: c-dropdown-tag;
  display: inline-block;
  padding: 0 25px 0 10px;
  position: relative;
  height: 37px;
  border-radius: 20px;
  line-height: 37px;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  background: map_get($grey, 98);
  border: 1px solid $fog-grey-1;
  color: $color-text-default;
  max-width: 120px;
  min-width: 80px;

  &:hover,
  &:active,
  &:focus {
    border-color: map_get($grey, 80);
  }

  &:after {
    transition: transform 0.2s;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    right: 10px;
    top: 15px;
    border-width: 9px 5px 0 5px;
    border-color: $color-neutral-one transparent transparent transparent;
  }

  &--open {
    &:after {
      transform: rotate(-60deg);
    }
  }

  &__dropdown-item {
    padding: 12px 20px;
  }

  &__text {
    max-width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
