
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-video-player-container {
  @include breakpoint(medium_up) {
    & .c-video__video {
      height: auto;
      max-height: 75vh;
      margin: 0 auto;
    }
  }

  &--player {
    @include breakpoint(small) {
      height: 100vh;
    }
  }

  &.with-panel &--player {
    @include breakpoint(small) {
      height: calc(100vh - 71px);
    }

    @include breakpoint(medium_up) {
      & .c-video__video {
        height: auto;
        max-height: calc(75vh - 120px);
      }
    }
  }
}
