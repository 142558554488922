
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-dropdown-checkbox {
  $group-item-height: 51px;
  $group-border: 1px;
  $search-height: 70px;
  $group-max-height: (4 * $group-item-height + 2 * $group-border);
  $group-with-search-max-height: ($group-max-height + $search-height);

  &__group-container {
    max-height: $group-max-height;
  }

  &__group-container-with-search {
    max-height: $group-with-search-max-height;
  }

  &__search {
    .c-input-label {
      display: none;
    }

    .c-input-wrapper {
      margin: 10px 10px 20px;
    }

    input {
      padding-right: 45px;
    }
  }

  & .c-checkbox-group__container {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .c-checkbox-group-item--nested {
    margin-left: 37px;

    .c-checkbox-group-item__marker {
      height: 26px;
    }
  }

  & .c-checkbox-group-item__expander,
  & .c-checkbox-group-item__group {
    width: 100%;
  }

  & .c-checkbox-group-item__toggle {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  & .c-checkbox-group-item__toggle--parent {
    left: -20px;
  }

  & .c-checkbox {
    font-weight: inherit !important;
    width: 100%;
    overflow: hidden;
    margin: 0 0 0 10px;

    & > label {
      width: 100%;
      margin: 0;
      padding: 13px 15px;

      & > input {
        width: auto;
      }

      &:hover,
      &:focus {
        background-color: $color-background-item-accent-default;
      }
    }

    &__label {
      width: auto;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &__label-postfix {
      padding: 0 5px;
      font-size: $font-size-small;
      margin-top: 3px;
    }
  }

  & .c-dropdown__options {
    @include backgroundSecondaryOverrides;

    .g-bg-secondary &,
    .g-bg-tertiary & {
      @include defaultCheckboxStyles;
    }
  }
}
