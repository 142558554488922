
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$action-height: 44px;

.c-action-list {
  $c: c-action-list;

  &--page-header {
    .#{$c}__inner {
      position: relative;
      padding-top: 10px;
      padding-bottom: 5px;
      gap: 8px;
    }

    .g-dropdown-list .#{$c}__link {
      padding-left: 0;
    }

    .#{$c}__link--hidden {
      display: none;
    }

    .#{$c}__link--opacity {
      opacity: 0;
      pointer-events: none;
    }

    .#{$c}__more-dropdown {
      position: absolute;
      right: 3px;
    }

    .#{$c}__more-dropdown-container {
      display: block;
      height: unset;
    }

    .#{$c}__shadow-link {
      height: 0;
      overflow: hidden;
    }

    .#{$c}__shadow-links {
      height: 0;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }

    .#{$c}__more-container {
      min-width: 0;
      align-items: center;
      position: absolute;
      right: 6px;
    }
  }

  &--name-bar {
    .#{$c}__more-button {
      align-self: center;
      height: 30px;
      color: $white;
      outline: none;

      &:focus {
        border: solid 1px $color-neutral-three;
        border-radius: 25px;
      }
    }

    .#{$c}__more-button-text {
      font-weight: $font-weight-semibold;
    }

    .#{$c}__inner {
      min-height: 0;
      justify-content: space-between;

      @include breakpoint(small) {
        & > .#{$c}__link:nth-child(n + 2) {
          display: none;
        }

        .#{$c}__more-container {
          display: none;
        }

        & > .#{$c}__link:nth-child(n + 2) + .#{$c}__more-container {
          display: block;
          position: relative;
        }
      }

      @include breakpoint(medium_up) {
        flex-flow: column;
        align-items: flex-start;

        & > .#{$c}__link {
          margin-bottom: 5px;
        }

        & > .#{$c}__link:nth-child(n + 3) {
          display: none;
        }

        .#{$c}__more-container {
          display: none;
        }

        & > .#{$c}__link:nth-child(n + 3) + .#{$c}__more-container {
          display: block;
          position: relative;
        }
      }

      .#{$c}__more-dropdown-container {
        height: unset;

        @include breakpoint(small) {
          & > .#{$c}__link:first-child {
            display: none;
          }
        }

        @include breakpoint(medium_up) {
          & > .#{$c}__link:nth-child(-n + 2) {
            display: none;
          }
        }
      }
    }

    .#{$c}__more-dropdown {
      @include breakpoint(medium_up) {
        left: -8px;
      }
    }
  }

  &--prevent-interaction {
    pointer-events: none !important;
  }

  &__inner {
    display: flex;
    min-height: $action-height;
    flex-wrap: wrap;
  }

  &__more-button {
    @include removeDefaultButtonStyling;
    padding: 0 10px;
    cursor: pointer;
    align-self: center;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-grey-2;
    border-radius: 50px;
    color: $color-brand-two;
    background-color: $transparent;

    &:active {
      .#{$c}__more-button-text {
        text-decoration: underline;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $sky-blue-2;
    }

    &:hover {
      text-decoration: none;
      color: $color-brand-two;
      border-color: $color-brand-two;
    }

    &:active,
    &:focus {
      color: $color-brand-two;
      border-color: $color-brand-two;
      text-decoration: underline;
    }

    &:focus {
      outline: 2px solid $blueFocus;
    }

    &--button-icon-text {
      background-color: $white;
    }
  }

  &__more-button-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__more-button-text {
    min-width: 0;
    flex-grow: 0;
    margin-top: -2px;
    padding-right: 4px;
    font-weight: $font-weight-semibold;
    font-size: $font-size-medium;
    font-family: $font-body;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__more-dropdown {
    min-width: 145px;
    transition: 0.3s;

    @include breakpoint(small) {
      right: -$outer-padding-sm-screen;
    }

    &--up {
      bottom: calc(100% + 5px);
    }

    &--down {
      top: calc(100% + 5px);
    }
  }
}
