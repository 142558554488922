
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.InlineMessage {
  padding: 12px;

  &--error {
    background-color: #fbedef;
  }

  &--success {
    background-color: #e5fbf6;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  &__title {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: inherit;
  }

  &__text {
    font-size: 14px;
    line-height: normal;
  }
}
