
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@import "../../../GlobalStyles/scrollbar";

$user-contaner-height: 58px;

@mixin AccountUnderlineColor($colour) {
  &:after {
    content: "";
    display: block;
    height: 8px;
    width: 100%;
    background-color: $colour;
    z-index: 1;
    position: relative;
  }
}

.menu-item-elipses {
  @include ellipsis();

  max-width: $menu-width-medium-up-device;

  @include breakpoint(small) {
    max-width: 100%;
  }
}

.c-nav-canvas {
  width: $menu-width-medium-up-device;
  height: 100%;
  background-color: $color-background-nav-canvas;
  border-right: 1px solid $light-grey-2;

  @include breakpoint(small) {
    width: 100%;
    border-right: none;
    overflow-y: auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__user-container {
    height: $user-contaner-height;
    transition: all 0.2s ease-in-out;
    background-color: $color-background-nav-canvas;
    z-index: 3;
    width: $menu-width-medium-up-device;
    transform: translate3d(0, 0, 0);

    @include breakpoint(small) {
      position: absolute;
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: $color-background-nav-canvas-title-hover;
    }

    &.c-nav-canvas__user-container-DefaultUser {
      @include AccountUnderlineColor(
        $color-border-bottom-underline-acount-default
      );
    }

    &.c-nav-canvas__user-container-CastingDirector {
      @include AccountUnderlineColor(
        $color-border-bottom-underline-acount-castingdirector
      );
    }

    &.c-nav-canvas__user-container-Performer {
      @include AccountUnderlineColor(
        $color-border-bottom-underline-acount-performer
      );
    }

    &.c-nav-canvas__user-container-Agent {
      @include AccountUnderlineColor(
        $color-border-bottom-underline-acount-agent
      );
    }
  }

  &__full-name {
    display: block;
    font-size: $font-size-medium;
    line-height: 22px;
    color: $color-neutral-one;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    padding: 14px 24px;
    border-right: 1px solid $light-grey-2;
    @extend .menu-item-elipses;
  }

  &__menu-container {
    height: calc(100vh - 50px);
    background: $color-background-nav-canvas;
    margin-top: 2px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @extend .menu-item-elipses;

    @include breakpoint(small) {
      height: unset;
      padding-top: $user-contaner-height;
    }

    @include breakpoint(medium_up) {
      @include styled-scrollbar($light-grey-2);
    }
  }
}
