/*
/ _settings.grid.scss
*/

//if you wish to reset default global:

$global-width: 1440px;
$grid-columns: 12;

$columnGridGapOnLarge: 30px;
$columnGridGapOnMedium: 20px;
$columnGridGapOnSmall: 10px;
