
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-popup {
  position: fixed;
  background-color: transparentize($color-neutral-one, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &--full-height {
    display: flex;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    @include breakpoint(small) {
      display: flex;
    }
  }

  &__col {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--full-height {
      padding: 10px 0;
      @include breakpoint(small) {
        padding: 0;
        flex-grow: 1;
      }
    }
  }

  &__col-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  &__container-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    position: relative;
    overflow-y: auto;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 transparentize($color-neutral-one, 0.8);
    -webkit-overflow-scrolling: touch;

    &:focus {
      outline: 0;
    }

    &--full-height {
      max-height: 100%;
      overflow-y: hidden;
      @include breakpoint(small) {
        flex-grow: 1;
        min-height: unset;
        max-height: unset;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &--full-height {
      overflow-y: auto;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 11px;
    right: 11px;
    font-size: $font-size-icon-default;
    cursor: pointer;
    z-index: 10;
    color: $color-neutral-two;
  }

  &--high {
    z-index: 10000;
    transform: translateZ(10000px);
  }

  &--medium {
    z-index: 1000;
    transform: translateZ(1000px);
  }

  &--content {
    z-index: 1;
  }
}
