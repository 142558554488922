
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$audio-icon-color: $white;
$audio-icon-size: 25px;

.c-audio-control {
  display: block;
  outline: none;
  background-color: unset;
  border: unset;
  padding: 0 2px;
  margin: 0 5px;
  cursor: pointer;

  &:before {
    color: $audio-icon-color;
    font-size: $audio-icon-size;
  }

  &:focus {
    outline: 2px solid $blueFocus;
    border-radius: 3px;
  }
}
