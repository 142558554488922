
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-crop-setting {
  height: 100%;
  display: flex;

  &__zoom {
    width: 100%;

    @include breakpoint(medium_up) {
      padding: 11px 54px 0 53px;
    }

    @include breakpoint(small) {
      padding: 4px 15px 0 15px;
    }
  }

  &__buttons.disabled &__button {
    pointer-events: none;
    color: $light-grey-2;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium_up) {
      padding-right: 35px;
    }

    @include breakpoint(small) {
      padding-right: 9px;
    }
  }
}
