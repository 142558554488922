
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@mixin input-style {
  display: block;
  background-color: $color-background-input-default;
  font-family: inherit;
  font-size: $font-size-base;
  color: $color-text-form-default;
  border: none;
  outline: $input-border-style;
  outline-offset: -1px;
  width: 100%;

  &:focus {
    outline: 2px solid $blueFocus;
    outline-offset: -2px;
  }
}

.c-input-wrapper {
  margin-bottom: $input-margin-bottom;
  position: relative;

  input,
  textarea {
    @include input-style;

    &:disabled {
      @include input-style-disabled-override;
    }
  }

  textarea,
  input[type='text'],
  input[type='button'],
  input[type='submit'],
  input[type='search'] {
    &:disabled {
      opacity: 1;
    }
  }

  .c-select-input__select {
    .c-select__selected {
      @include input-style;
    }
    &.disabled .c-select__selected {
      @include input-style-disabled-override;
    }
  }

  &--invalid-error {
    input,
    .c-select-input__select .c-select__selected,
    .c-dropdown__control {
      outline-color: $color-input-validation-error-border;

      &:focus {
        box-shadow: inset 0px 0px 0px 3px $color-input-validation-error-border;
      }
    }

    .c-input-wrapper:not(.c-input-wrapper--invalid-error) input,
    .c-input-wrapper:not(.c-input-wrapper--invalid-error) textarea {
      outline-color: $color-border-input-default;
    }

    .c-select-input__select .c-select__list.opened {
      margin-top: 1px;
      outline-color: $color-input-validation-error-border;
    }
  }

  &--invalid-warning {
    input,
    .c-select-input__select .c-select__selected,
    .c-dropdown__control {
      outline-color: $color-input-validation-warning-border;
    }

    .c-input-wrapper:not(.c-input-wrapper--invalid-warning) input,
    .c-input-wrapper:not(.c-input-wrapper--invalid-warning) textarea {
      outline-color: $color-border-input-default;
    }
  }
}
