
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$showreelHeight: 132px;

.c-video-player {
  height: 100%;

  &--with-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .c-video {
      @include breakpoint(medium_up) {
        height: calc(100% - #{$showreelHeight});
        display: flex;
      }
    }
  }
}
