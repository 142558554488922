
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$menu-transition-time: 0.5s;
$nav-button-font: $font-primary;

:export {
  menuTransitionTime: strip-unit($menu-transition-time);
}

@mixin NavItemBorder($sides) {
  @each $side in $sides {
    border-#{$side}: 1px solid $color-border-side-header-nav-item;
  }
}

@mixin MenuTransition {
  transition: width $menu-transition-time;
}

@mixin NavItem {
  background-color: $color-background-header-nav-item;

  &:hover,
  &:focus {
    background-color: $color-background-header-nav-item-highlight;
  }
}

@mixin AccountButtonUnderline($colour) {
  border-bottom: 3px solid $colour;

  &:hover,
  &:focus {
    border-bottom: 3px solid $colour;
  }
}

$navHeader: c-navigation-header;

.#{$navHeader} {
  background-color: $color-neutral-one;

  &__container-root {
    height: 50px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__container-menu {
    min-width: 115px;

    & .menu-label {
      width: 38px;
      height: 20px;
      font-size: $font-size-medium;
      font-family: $font-primary;
      font-weight: $font-weight-semibold;
      text-align: center;
      color: $white;

      @include breakpoint(medium_up) {
        padding-left: 3px;
      }
    }

    @include breakpoint(small) {
      min-width: 70px;
    }
  }

  &__container-logo {
    color: $white;
    width: 156px;
    height: 30px;
    z-index: 2;
    display: block;
    padding: 0 15px;
  }

  &__container-nav {
    font-weight: $font-weight-semibold;
    color: $white;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include breakpoint(small) {
      display: block;
    }
  }

  &__logo {
    display: block;
    width: 126px;

    &:focus {
      outline: 2px solid $blueFocus;
      outline-offset: 2px;
    }
  }

  &__container-test-env-warning {
    color: $cardinal-red;
    background-color: $white;
    border: 2px solid $cardinal-red;
    padding: 2px 8px;
    margin: 0 8px;
    font-weight: $font-weight-bold;
    white-space: nowrap;

    @include breakpoint(small) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__nav-toggle {
    cursor: pointer;
    z-index: 2;
    line-height: 50px;
    height: 50px;
    vertical-align: middle;
    padding: 0 15px;

    &:focus {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px solid $blueFocus;
      }
    }

    @include breakpoint(medium) {
      padding: 0 15px 0 10px;
    }

    display: table;

    & > * {
      display: table-cell;
      vertical-align: middle;
    }

    &:before {
      font-size: 40px;
      color: $white;
      display: table-cell;
      vertical-align: middle;
      padding-right: 5px;

      @include breakpoint(small) {
        padding-right: 0;
      }
    }
  }

  &__nav-item {
    display: flex;
    color: $white;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    height: 50px;
    line-height: 50px;
    @include NavItem();

    &.nav-item--border-left {
      @include NavItemBorder(left);
    }

    &.nav-item--border-right {
      @include NavItemBorder(right);
    }
  }

  &__nav-button {
    border: none;
    border-radius: 1px;
    padding: 0 15px;
    line-height: 50px;
    max-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: $font-size-medium;

    &:focus {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px solid $blueFocus;
      }
    }

    &.nav-button-primary {
      color: $white;
      font-family: $nav-button-font;
      font-weight: $font-weight-semibold;
      text-transform: none;
      @include NavItem();
    }

    &.nav-button-secondary {
      color: $color-brand-one;

      @include NavItem();
    }

    &.nav-button-last-item {
      @include breakpoint(medium) {
        padding: 0 10px 0 15px;
      }
    }

    @include breakpoint(small) {
      text-align: left;
      justify-content: left;
      max-width: none;
      padding: 0 0 0 20px;
      font-size: $font-size-small;
    }

    &.nav-button-underline {
      &-DefaultUser {
        @include AccountButtonUnderline(
          $color-border-bottom-underline-acount-default
        );
      }

      &-CastingDirector {
        @include AccountButtonUnderline(
          $color-border-bottom-underline-acount-castingdirector
        );
      }

      &-Performer {
        @include AccountButtonUnderline(
          $color-border-bottom-underline-acount-performer
        );
      }

      &-Agent {
        @include AccountButtonUnderline(
          $color-border-bottom-underline-acount-agent
        );
      }
    }
  }

  &__menu-container {
    overflow: hidden;
    height: 100%;
    position: absolute;
    will-change: width;

    @include breakpoint(large) {
      position: relative;
    }

    @include breakpoint(small) {
      @include MenuTransition();
      width: 0;
    }

    &.#{$navHeader}__menu-container--active {
      @include MenuTransition();
      width: 100%;

      @include breakpoint(medium_down) {
        position: absolute;
      }

      @include breakpoint(large) {
        position: relative;
      }

      .#{$navHeader}__menu__blocker {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color-neutral-one;
        opacity: 0.4;

        @include breakpoint(medium) {
          display: block;
        }
      }
    }
  }

  &__menu {
    overflow: hidden;
    position: relative;
    height: 100%;
    will-change: width;
    pointer-events: none;
    visibility: hidden;

    @include breakpoint(small) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: white;
    }

    @include breakpoint(medium_up) {
      width: 0;
      @include MenuTransition();
    }

    &.#{$navHeader}__menu--active {
      width: 100%;
      pointer-events: auto;
      visibility: initial;

      @include breakpoint(small) {
        height: 100%;
        position: static;
        overflow-y: auto;
      }

      @include breakpoint(medium_up) {
        width: $menu-width-medium-up-device;
        position: relative;
      }
    }
  }

  &__container-country {
    @include breakpoint(small) {
      border-top: 1px, solid, $shark-grey-5;
    }
  }

  &__search {
    margin-left: auto;
  }
}

/* forcing layout redraw in Safari on window size change */

.is-safari .a-layout__menu-container {
  opacity: 0.99;
}

.is-safari.medium-screen .a-layout__menu-container {
  opacity: 1;
}
