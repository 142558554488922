
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$grey-gradient: #f0f0f0;

.c-shimmering-stub {
  background: linear-gradient(to top, $color-neutral-three, $grey-gradient);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 250%;
    min-height: 110px;
    margin-left: 60px;
    background: linear-gradient(
      -90deg,
      rgba($white, 0.1),
      $white,
      rgba($white, 0.1)
    );
    opacity: 0.7;
    position: absolute;
    top: -65px;
    z-index: 1;
    transform: rotate(45deg);
    animation-name: light;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes light {
  0% {
    left: -100%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
