
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-copyright {
  background-color: $color-background-footer-copyright;
  font-family: $font-primary;
}

.c-copyright__inner {
  padding: $spacing-medium 0;
  text-align: center;
}

.c-copyright__notice {
  display: block;
  margin-bottom: $spacing-x-small;
  color: $color-text-copyright;
  font-size: calculate-rem(12px);
}

.c-copyright__address {
  display: block;
  color: $color-text-copyright;
  font-size: calculate-rem(12px);
}
