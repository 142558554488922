
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-actions-footer {
  $c: c-actions-footer;
  width: 100%;
  max-width: $global-width;
  margin: 0 auto;
  z-index: 1;

  &.g-content-area {
    padding: 0;
  }

  &--sticky {
    position: sticky;
    bottom: 0;
  }

  &--secondary {
    .#{$c} {
      &__container {
        box-shadow: 0 -8px 10px 0 rgba($color-neutral-one, 0.2);
        background-color: $color-neutral-three;
        border-bottom-color: $white;
      }

      &__text {
        color: $color-text-default;
      }
    }
  }

  &__anchor {
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }

  &__container {
    @include globalOuterPadding;
    border-bottom: 1px solid $color-neutral-two;
    border-radius: 5px 5px 0 0;
    background-color: $color-neutral-one;
    box-shadow: 0 -6px 8px -5px rgba(0, 0, 0, 0.4);
  }

  &__content {
    padding: 0;
    margin: 5px 0;

    @include breakpoint(small) {
      margin: 10px 0;
    }
  }

  &__text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 1px;
    color: $white;
    font-size: $font-size-base;
    line-height: 1.5;
    grid-column: auto;

    @include breakpoint(small) {
      padding-bottom: 8px;
    }
  }

  &__control {
    position: relative;
    grid-row-gap: 5px;
  }
}
