
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.payment-method-LEGACY {
  @apply flex items-center gap-5 bg-color-neutral-three px-5 border border-color-neutral-one border-dashed rounded-[5px] cursor-pointer;

  &--primary {
    @apply text-color-brand-one font-semibold py-[34px];

    svg {
      path {
        @apply fill-color-brand-one;
      }
    }
  }

  &--secondary {
    @apply py-6;
  }

  &--selected {
    @apply bg-white border-color-neutral-one border-solid border-l-[4px] border-l-color-brand-one;
  }
}
