
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$nested-checkbox-marker-width: 17px;
$nested-checkbox-marker-height: 19px;
$nested-checkbox-marker-thickness: 1px;
$checkbox-padding-horizontal: 10px;
$checkbox-padding-vertical: 8px;
$nested-checkbox-marker-border: $nested-checkbox-marker-thickness solid $color-border-checkbox-nest;


.c-checkbox-group-item {
  display: flex;
  flex-direction: row;
  position: relative;

  &--nested {
    margin-left: 22px;

    .c-checkbox-group-item__marker {
      height: $nested-checkbox-marker-height;
      width: $nested-checkbox-marker-width;
      border-bottom: $nested-checkbox-marker-border;
    }

    &:not(:last-child) {
      border-left: $nested-checkbox-marker-border;
    }

    &:last-child > .c-checkbox-group-item__marker {
      border-left: $nested-checkbox-marker-border;
      width: $nested-checkbox-marker-width + $nested-checkbox-marker-thickness;
    }

    .c-checkbox {
      margin-bottom: 0;
    }
  }

  .c-checkbox {
    margin: $checkbox-padding-vertical $checkbox-padding-horizontal;

    &.c-checkbox-group-item--checked {
      font-weight: $font-weight-semibold;
    }

    &__annex {
      margin-left: 10px;
    }

    &__label-postfix {
      margin-left: 5px;
      font-size: $font-size-small;
      margin-top: 3px;
    }
  }

  &__toggle {
    height: 41px;
    padding: 11px 6px;
    color: $color-border-checkbox-default;
    cursor: pointer;

    &--parent {
      position: absolute;
      left: -30px;
    }
  }

  &__expander {
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  &__status {
    &--partial {
      font-weight: $font-weight-semibold;

      .c-checkbox__box:before {
        background-color: $color-background-checkbox-selected;
        top: 8px;
        bottom: 8px;
      }
    }

    &--all {
      font-weight: $font-weight-semibold;
    }
  }
}
