
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
@include defaultFilterTagStyles;

@mixin filter-tag-base {
  display: inline-block;
  min-width: 50px;
  max-width: 290px;
  padding: 5px 10px;
  border: 1px solid $medium-grey-3;
  font-size: $font-size-medium;
}

@mixin filter-tag-base--with-icon {
  padding-right: 30px;
}

.c-filter-tag {
  $c: c-filter-tag;
  @include filter-tag-base;
  position: relative;
  height: 30px;
  border-radius: 15px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  outline: none;

  @include breakpoint(small) {
    margin-right: auto;
    margin-left: auto;

    & + & {
      margin-bottom: 15px;
    }
  }

  @include breakpoint(medium_up) {
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .#{$c}__icon {
    color: $color-text-default;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $sky-blue-2;
  }

  &:active,
  &:focus {
    border-color: $color-brand-two;
  }

  &:focus {
    border: 2px solid $color-brand-two;
  }

  &--with-icon {
    @include filter-tag-base--with-icon;
  }

  &__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: $font-weight-semibold;
  }

  &__icon {
    z-index: 1;
    position: absolute;
    top: 7px;
    right: 7px;
    height: 16px;
    width: 16px;
    color: $color-neutral-two;
  }
}
