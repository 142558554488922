
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$video-controls-component: c-video-controls;

@mixin volume-range-thumb {
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 14px;
  height: 14px;
  background-color: $white;
}

@mixin volume-runnable-track {
  appearance: none;
  background-color: $transparent;
  cursor: pointer;
  height: 8px;
  border-radius: 40px;
}

.#{$video-controls-component} {
  background-color: transparentize($color-neutral-one, 0.6);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 1px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;

  @include breakpoint(small) {
    opacity: 1;
  }

  &__time {
    margin-left: 12px;
    font-family: $font-body;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    color: $white;
  }

  &__loading {
    margin-left: 12px;
  }

  &__control {
    width: 35px;
    height: 34px;
    @include removeDefaultButtonStyling;
    cursor: pointer;
    outline: none;

    &:before {
      color: $white;
      font-size: $font-size-icon-default;
    }

    &:active,
    &:focus {
      &:before {
        color: $light-grey-2;
      }
      outline: 2px solid $blueFocus;
      border-radius: 3px;
    }

    &--expanding {
      &:before {
        font-size: 40px;
      }
    }
  }

  &__time-bar {
    width: 100%;
    cursor: pointer;
    background-color: $color-neutral-two;
    height: 4px;
    position: absolute;
    top: 0;
  }

  &__time-line {
    width: 100%;
    position: absolute;
    left: 0;
    height: 4px;
    margin: 0;
    cursor: pointer;
    opacity: 0;
  }

  &__time-line-bg {
    background-color: $cardinal-red;
    height: 4px;
    display: inline-block;
    max-width: 100%;
    position: absolute;
    left: 0;
  }

  &__controls-group {
    margin-left: 6px;
    margin-top: 4px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: 0;
      margin-right: 6px;
    }
  }

  &__volume-wrapper {
    position: relative;

    &:hover {
      .#{$video-controls-component}__volume-track-bar {
        @include breakpoint(medium_up) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__volume-track-bar {
    position: absolute;
    bottom: 74px;
    left: -40px;
    height: 34px;
    width: 110px;
    background: transparentize($color-neutral-one, 0.6);
    border-radius: 0 5px 5px 0;
    transform: rotateZ(270deg);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  &__volume-runnable-track {
    position: absolute;
    top: 13px;
    left: 16px;
    background-color: $color-neutral-two;
    height: 8px;
    width: 78px;
    border-radius: 40px;
  }

  &__volume-level-value {
    position: absolute;
    background-color: $white;
    height: 8px;
    border-radius: 40px;
    top: 13px;
    left: 16px;
  }

  &__volume-range {
    position: absolute;
    appearance: none;
    display: block;
    border-radius: 0 5px 5px 0;
    margin: 0;
    width: 110px;
    padding: 13px 16px;

    &::-webkit-slider-runnable-track {
      @include volume-runnable-track;
    }

    &::-moz-range-track {
      @include volume-runnable-track;
    }

    &::-webkit-slider-thumb {
      @include volume-range-thumb;

      margin-top: -3px;
    }

    &::-moz-range-thumb {
      @include volume-range-thumb;
    }
  }
}
