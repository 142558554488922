
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.a-master-page {
  width: 100%;
  height: 100vh;
  background-color: $white;
  position: relative;

  &__header {
    z-index: 1000;
  }

  &__body {
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    display: flex;
    position: relative;
  }

  @media print {
    width: 100%;
    height: 100%;

    &__body {
      display: block;
      width: auto;
      height: auto;
      overflow: hidden;
    }
  }

  &__menu-container {
    height: 100%;
    z-index: 1000;
  }

  &__content-container {
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  &__content {
    flex: 1 0;
    background-color: $color-neutral-three;
    position: relative;
  }

  &__footer {
    position: relative;
  }
}
