@mixin defaultFilterTagStyles {
  .c-filter-tag {
    background: $color-neutral-three;

    &:hover {
      background-color: $color-neutral-four;
    }
  }
}

@mixin alternativeFilterTagStyles {
  .c-filter-tag {
    background: $white;

    &:hover {
      background-color: $color-neutral-four;
    }
  }
}

@mixin defaultCheckboxStyles {
  .c-checkbox .c-checkbox__box {
    background-color: $color-background-checkbox-default;
  }
}

@mixin backgroundDefaultOverrides {
  .c-input-wrapper {
    input,
    textarea {
      background-color: $color-background-input-default;
    }

    label {
      color: $color-text-form-default;
    }

    &__validation--error {
      color: $color-input-validation-error-text;
    }

    &__validation--warning {
      color: $color-input-validation-warning-text;
    }

    &__validation--notification {
      color: $color-input-validation-notification-text;
    }
  }

  .c-checkbox .c-checkbox__box {
    background-color: $color-background-input-default;
  }

  .c-radio {
    &__box {
      background: $color-background-checkbox-default;
    }

    &__text {
      color: $color-text-form-default;
    }
  }

  .c-autocomplete {
    background-color: $color-background-item-default;

    &__item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-default;
      }
    }
  }

  .c-select {
    &__control {
      background-color: $color-background-item-default;
    }

    &__list-item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-default;
      }
    }

    &__list {
      background-color: $color-background-item-default;
    }

    &__list-item.selected {
      background-color: $color-background-item-accent-default;
    }
  }

  .c-select-input {
    .c-select__selected {
      background-color: $color-background-item-default;
    }
  }

  .c-dropdown-input {
    &__entry {
      background-color: $color-background-item-default;

      &:hover,
      &.active {
        background-color: $color-background-item-accent-default;
        outline: none;
      }

      &--disabled {
        background-color: $color-neutral-three;
      }
    }
  }

  .c-checkbox-group__status--partial .c-checkbox__box::before {
    border-color: $color-background-input-default;
  }

  .c-checkbox-group-item__toggle {
    color: $color-text-form-default;
  }

  .c-dropdown {
    &__control,
    &__options-container {
      background-color: $color-background-input-default;
    }
  }

  .c-dropdown-checkbox .c-checkbox {
    label {
      color: $color-text-form-default;

      &:hover,
      &:focus {
        background-color: $color-background-item-accent-default;
      }
    }
  }

  .c-textarea__character-count {
    background-color: $color-background-input-default;
  }

  @include defaultFilterTagStyles;
}

@mixin backgroundDefault {
  background-color: $color-background-form-default;
  color: $color-text-form-default;

  @include backgroundDefaultOverrides;
}

@mixin backgroundSecondaryOverrides {
  .c-input-wrapper {
    input,
    textarea {
      background-color: $color-background-input-secondary;
      &:disabled {
        @include input-style-disabled-override;
      }
    }

    label {
      color: $color-text-form-secondary;
    }

    &__validation--error {
      color: $color-input-validation-error-text;
    }

    &__validation--warning {
      color: $color-input-validation-warning-text;
    }

    &__validation--notification {
      color: $color-input-validation-notification-text;
    }
  }

  .c-checkbox .c-checkbox__box {
    background-color: $color-background-input-secondary;
  }

  .c-radio {
    &__box {
      background: $white;
    }

    &__text {
      color: $color-text-form-secondary;
    }
  }

  .c-autocomplete {
    background-color: $color-background-item-secondary;

    &__item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-secondary;
      }
    }
  }

  .c-select {
    &__control {
      background-color: $color-background-item-secondary;
    }

    &__list-item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-secondary;
      }
    }

    &__list {
      background-color: $color-background-item-secondary;
    }

    &__list-item.selected {
      background-color: $color-background-item-accent-secondary;
    }
  }

  .c-select-input {
    .c-select__selected {
      background-color: $color-background-item-secondary;
    }
  }

  .c-dropdown-input {
    &__entry {
      background-color: $color-background-item-secondary;

      &:hover,
      &.active {
        background-color: $color-background-item-accent-secondary;
        outline: none;
      }

      &--disabled {
        background-color: $color-neutral-three;
      }
    }
  }

  .c-checkbox-group__status--partial .c-checkbox__box::before {
    border-color: $color-background-input-secondary;
  }

  .c-checkbox-group-item__toggle {
    color: $color-text-form-secondary;
  }

  .c-dropdown {
    &__control,
    &__options-container {
      background-color: $color-background-input-secondary;
    }
  }

  .c-dropdown-checkbox .c-checkbox {
    label {
      color: $color-text-form-default;

      &:hover,
      &:focus {
        background-color: $color-background-item-accent-secondary;
      }
    }
  }

  .c-action-button-holder {
    color: $color-brand-one;

    &:hover {
      color: $color-background-button-primary-hover;
    }

    &:focus {
      color: $color-brand-two;
      outline: 2px solid $blueFocus;
      border-radius: 4px;
    }
  }

  .c-textarea__character-count {
    background-color: $color-background-input-secondary;
  }

  .c-button__tertiary {
    background: $white;
  }

  @include alternativeFilterTagStyles;
}

@mixin backgroundSecondary {
  background-color: $color-background-form-secondary;
  color: $color-text-form-secondary;

  @include backgroundSecondaryOverrides;

  .c-input-group--default,
  .g-bg-default {
    @include backgroundDefaultOverrides;
  }
}

@mixin backgroundTertiaryOverrides {
  .c-input-wrapper {
    input,
    textarea {
      background-color: $color-background-input-tertiary;
      &:disabled {
        @include input-style-disabled-override;
      }
    }
  }

  .c-checkbox .c-checkbox__box {
    background-color: $color-background-input-tertiary;
  }

  .c-radio {
    &__box {
      background: $white;
    }

    &__text {
      color: $white;
    }
  }

  .c-autocomplete {
    background-color: $color-background-item-tertiary;

    &__item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-tertiary;
      }
    }
  }

  .c-select {
    &__control {
      background-color: $color-background-item-tertiary;
    }

    &__list-item {
      &:hover,
      &:focus {
        background-color: $color-background-item-accent-tertiary;
      }
    }

    &__list {
      background-color: $color-background-item-tertiary;
    }

    &__list-item.selected {
      background-color: $color-background-item-accent-tertiary;
    }
  }

  .c-select-input {
    .c-select__selected {
      background-color: $color-background-item-tertiary;
    }
  }

  .c-dropdown-input {
    &__entry {
      background-color: $color-background-item-tertiary;

      &:hover,
      &.active {
        background-color: $color-background-item-accent-tertiary;
        outline: none;
      }

      &--disabled {
        background-color: $color-neutral-three;
      }
    }
  }

  .c-checkbox-group__status--partial .c-checkbox__box::before {
    border-color: $color-background-input-tertiary;
  }

  .c-checkbox-group-item__toggle {
    color: $color-text-form-tertiary;
  }

  .c-dropdown {
    &__control,
    &__options-container {
      background-color: $color-text-form-tertiary;
    }
  }

  .c-dropdown-checkbox .c-checkbox {
    label {
      color: $color-text-form-default;

      &:hover,
      &:focus {
        background-color: $color-background-item-accent-tertiary;
      }
    }
  }

  .c-tags-panel__clear-all {
    color: $color-text-link;
  }

  .c-action-button-holder {
    color: $color-text-button-primary;

    &:hover {
      color: $color-text-action-button-tertiary-hover;
    }

    &:focus {
      color: $white;
      outline: 2px solid $blueFocus;
      border-radius: 3px;
    }
  }

  .c-textarea__character-count {
    background-color: $color-background-input-secondary;
    color: $color-text-form-default;
    &--warning {
      color: $color-input-validation-warning-text;
    }

    &--error {
      color: $color-input-validation-error-text;
    }
  }

  @include breakpoint(small) {
    .c-filter-control__filter-row-view-all {
      .c-icon-text-link__icon,
      .c-icon-text-link__text {
        color: $color-text-link;
      }
    }
  }

  @include alternativeFilterTagStyles;
}

@mixin backgroundTertiary {
  background-color: $color-background-form-tertiary;

  @include backgroundTertiaryOverrides;

  .c-input-group--secondary,
  .g-bg-secondary {
    @include backgroundSecondaryOverrides;
  }

  .c-input-group--default,
  .g-bg-default {
    @include backgroundDefaultOverrides;
  }
}
