
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-navigation-header__country-flag {
  position: relative;
  background-position: 15px 10px;
  height: 50px;
  width: 76px;
  cursor: pointer;
  display: block;

  &:focus {
    outline: 2px solid $blueFocus;
    border-radius: 3px;
  }

  @include breakpoint(small) {
    width: 100%;
    background-position: 20px 10px;
  }

  @include breakpoint(medium) {
    width: 71px;
  }

  &:after {
    content: "";
    width: 0;
    position: absolute;
    top: 22px;
    left: 55px;
    @include arrow("bottom", $color-neutral-two, 0px, 6px, 3px, 0px, 3px);
  }

  &.country-flag--size-30 {
    background-size: 30px;
  }
}
