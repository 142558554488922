
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-file-uploader__file-preview {
  background-color: $color-neutral-three;
  height: 55px;
  @apply h-fit;

  &.error {
    @apply border border-solid border-red-cardinal;
  }

  & .c-progress-bar {
    width: 130px;
  }

  &--error-text {
    color: $color-input-validation-error-text;
    font-weight: $font-weight-semibold;
    font-size: $font-size-small;
    margin-right: 10px;
  }
}
