
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.pill {
  $c: pill;

  padding: 2px 10px;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  gap: 5px;

  &--dark {
    background-color: $color-neutral-one;

    .#{$c}__children-container {
      color: $white;
    }
  }

  &--light {
    background-color: $white;

    .#{$c}__children-container {
      color: $color-text-default;
    }
  }

  &--lg {
    padding: 4px 10px;

    .#{$c}__children-container {
      font-size: 16px;
    }

    .#{$c}__icon-container {
      width: 27px;
      height: 27px;
    }
  }

  &--md {
    .#{$c}__children-container {
      font-size: 12px;
    }

    .#{$c}__icon-container {
      width: 15px;
      height: 15px;
    }
  }

  &--link-to-profile {
    padding: 6px 10px;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__icon-img {
    max-width: 100%;
    max-height: 100%;
  }

  &__children-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: $font-weight-semibold;
  }
}
