
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-tool-bar {
  display: flex;
  height: 100%;
  justify-content: space-between;
  color: $color-brand-one;

  &__tool-set {
    display: flex;
    width: 100%;
  }

  &.disabled &__tool {
    color: $color-text-disabled;
    &:before {
      color: $color-text-disabled;
    }
  }

  &__undo {
    position: relative;
    text-align: center;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: $sky-blue-2;
    }

    &.disabled {
      color: $color-text-disabled;
      &:before {
        color: $color-text-disabled;
      }

      &:hover {
        background-color: transparent;
      }
    }

    @include breakpoint(medium_up) {
      width: 120px;
      padding-top: 73px;
    }

    @include breakpoint(small) {
      width: 70px;
      padding-top: 48px;
    }

    &:before {
      position: absolute;

      @include breakpoint(medium_up) {
        font-size: 50px;
        top: 20px;
        left: calc(50% - 25px);
      }

      @include breakpoint(small) {
        font-size: 40px;
        left: calc(50% - 20px);
        top: 6px;
      }
    }
  }

  &__tool {
    position: relative;
    color: $color-brand-one;
    text-align: center;
    cursor: pointer;
    font-size: 16px;

    @include breakpoint(medium_up) {
      width: 120px;
      padding-top: 73px;
    }

    @include breakpoint(small) {
      width: 100%;
      padding-top: 48px;
    }

    &:before {
      position: absolute;

      @include breakpoint(medium_up) {
        font-size: 50px;
        top: 20px;
        left: calc(50% - 25px);
      }

      @include breakpoint(small) {
        font-size: 40px;
        left: calc(50% - 20px);
        top: 6px;
      }
    }

    &.active::before {
      color: $color-brand-one;
    }

    &.active,
    &:hover {
      background-color: $sky-blue-2;
    }
  }

  &__tool-name {
    font-family: $font-body;
    font-weight: $font-weight-regular;
  }
}
