
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-photo-editor {
  @include breakpoint(medium_up) {
    padding: 18px 20px;
  }
  @include breakpoint(small) {
    padding-top: 10px;
  }

  &__container {
    position: relative;
  }

  &__title {
    line-height: 40px;
    color: $color-neutral-one;
    cursor: default;

    @include breakpoint(medium_up) {
      padding-left: 10px;
      font-size: 26px;
      padding-bottom: 16px;
    }

    @include breakpoint(small) {
      font-size: 22px;
      padding-left: 14px;
    }
  }

  &__tool-settings {
    background-color: $color-brand-two;

    @include breakpoint(medium_up) {
      height: 84px;
    }

    @include breakpoint(small) {
      height: 55px;
    }
  }

  &__tool-bar {
    @include breakpoint(medium_up) {
      height: 120px;
    }
    @include breakpoint(small) {
      height: 71px;
    }
  }

  &__editor-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(medium_up) {
      padding: 30px;
      height: 120px;
    }
    @include breakpoint(small) {
      height: 90px;
      padding: 20px 15px;
    }
  }

  &__editor-buttons {
    display: flex;

    @include breakpoint(medium_down) {
      width: 100%;
      justify-content: space-around;
    }
  }

  &__editor-button {
    @include breakpoint(large) {
      margin-left: 30px;
      width: 210px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: 210px;
    }

    @include breakpoint(small) {
      width: 100%;
    }
  }
}
