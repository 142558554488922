
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-inbox-row-tags {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 5px;
    word-break: break-word;
    font-weight: $font-weight-semibold;
    font-size: $font-size-tiny;

    &:last-child {
      margin-right: 0;
    }

    a:not([class]) {
      @include secondary-link;

      &:hover,
      &:focus {
        text-decoration: underline;
        outline: 0;
      }
    }
  }
}
