@mixin styled-scrollbar($color: $color-neutral-one) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color, 0.5);
    background-clip: padding-box;
    border-style: solid;
    border-color: transparent;
    border-width: 0 4px;
    border-radius: 5px;
  }
}
