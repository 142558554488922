
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-quick-view-header {
  padding: 10px 10px 9px 0;
  background: white;

  @include breakpoint(medium) {
    padding-left: 10px;
  }

  @include breakpoint(small) {
    padding-left: 10px;
  }

  &__link {
    @include secondary-link;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
    text-transform: uppercase;
    display: inline;

    &:hover,
    &:focus {
      outline: none;

      span {
        text-decoration: underline;
      }
    }
  }

  &__icon-font {
    font-size: 23px;
    margin-left: 5px;
    vertical-align: bottom;
  }
}
