
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-links-list {
  vertical-align: top;
  text-align: left;
  font-family: $font-primary;
  font-size: calculate-rem(16px);
  color: $color-neutral-one;

  @include breakpoint(medium down) {
    margin-top: 10px;
  }

  &__site-links {
    &-header {
      width: 100%;
      line-height: initial;
      border-bottom: 1px solid $white;
      padding-bottom: 9px;

      @include breakpoint(medium down) {
        text-align: center;
        width: 100%;
      }
    }

    &-list {
      margin-top: 12px;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;

      li {
        margin-top: 4px;
        text-align: inherit;
        line-height: initial;
        list-style: none;
        a {
          color: unset; // Take color from whole component container
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
