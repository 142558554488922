
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$audio-track-title-padding: 39px;
$audio-track-title: $shark-grey-5;
$audio-font-color: $white;

.c-audio {
  &__title {
    background-color: $audio-track-title;
    padding: 17px 15px;
    padding-right: $audio-track-title-padding;
    color: $audio-font-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title-prefix {
    text-transform: uppercase;
  }
}
