
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-change-main-photo-view {
  &__header {
    color: $color-neutral-one;
    box-shadow: 0 1px $medium-grey-3;

    @include breakpoint(medium_up) {
      font-size: 26px;
      padding: 20px 30px;
    }

    @include breakpoint(small) {
      font-size: 22px;
      padding: 9px 10px;
    }
  }

  &__description {
    font-size: 18px;

    @include breakpoint(medium_up) {
      padding: 20px;
    }

    @include breakpoint(small) {
      padding: 20px 13px;
    }
  }

  &__gallery {
    background-color: $color-neutral-three;

    @include breakpoint(medium_up) {
      padding: 20px;
    }

    @include breakpoint(small) {
      padding: 20px 13px;
    }
  }

  &__buttons {
    background-color: $color-brand-two;

    @include breakpoint(medium_up) {
      display: flex;
      justify-content: flex-end;
      padding: 30px;
      margin: 0 20px 20px 20px;
    }

    @include breakpoint(small) {
      padding: 10px;
      display: flex;
      flex-flow: column-reverse;
    }
  }

  &__button {
    @include breakpoint(medium_up) {
      width: 210px;
      margin-left: 30px;
    }

    @include breakpoint(small) {
      margin: 5px 0;
    }
  }
}
