
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-helper {
  display: flex;
  align-items: center;

  &__trigger {
    text-align: center;
    border-radius: 50%;
    cursor: pointer;

    &-red {
      background-color: $color-brand-one;
      color: $white;
    }

    &-white {
      color: $color-brand-one;
      border: 1px solid $color-brand-one;
      font-weight: $font-weight-semibold;
    }

    &.c-helper__trigger-medium {
      width: 29px;
      height: 29px;
      line-height: 27px;
      font-size: $font-size-large;
    }

    &.c-helper__trigger-small {
      width: 20px;
      height: 20px;
      font-size: $font-size-medium;
      line-height: 18px;
    }

    &.c-helper__trigger-tiny {
      width: 16px;
      height: 16px;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__link {
    line-height: 23px;
    margin-left: 10px;
    color: $color-brand-one;
    font-weight: $font-weight-semibold;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
