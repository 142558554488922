
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-copyright-info {
  background-color: $color-neutral-three;
  font-size: 10px;
  color: map-get($grey, 45);

  & p {
    line-height: normal;
    margin: 0;
    font-size: 10px;
  }
}
