
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.popup-media-viewer {
  .c-video__video {
    margin: 0 auto;
    max-height: 75vh;
  }
}



