
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$toggle-button-width: $input-height;

.c-date-picker {
  display: flex;
  position: relative;

  .input-wrapper {
    padding: 0;

    input {
      width: 100%;
      height: 48px;
      border-radius: 0;
      outline: none;
      border: none;
      background: $color-neutral-three;
      padding-left: 12px;
      color: $color-neutral-one;
      font-family: $font-primary;
      font-size: 18px;
      box-shadow: none;
      padding-right: 50px;

      &:focus {
        border: none;
        box-shadow: none;
        outline: 1px solid $blueFocus;
        outline-offset: 1px;
      }
    }
  }

  @include breakpoint(small) {
    .input-wrapper input {
      padding-right: 40px;
    }
  }

  .input-wrapper .react-datepicker__input-container {
    border-radius: 0;
    border: none;
    border: 1px solid $color-neutral-two;
    height: 50px;
    width: 100%;
  }

  .input-wrapper--error .react-datepicker__input-container {
    border-color: $cardinal-red;
  }

  .react-datepicker {
    font-family: $font-primary;
    font-size: $font-size-base;
    border-radius: 0;
    border-color: #e5e5e5;
    background: $color-neutral-three;
    padding: 15px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
    max-width: calc(100vw - 25px);

    &-popper {
      z-index: 100;
    }

    &__month-container {
      background-color: white;
      border: 1px solid #e5e5e5;
      z-index: 100;
      width: 100%;
    }

    &__current-month {
      color: $color-neutral-one;
      font-family: $font-primary;
      font-size: 18px;
      line-height: normal;
      font-weight: $font-weight-semibold;
      text-transform: capitalize;
      margin-bottom: 0.8rem;
    }

    &__header {
      background-color: $white;
      border-bottom: none;
      position: relative;
    }

    &__day-name {
      width: 55px;
      font-size: 14px;
      font-weight: $font-weight-semibold;
      color: $color-neutral-one;
      margin: 0;
    }

    &__navigation {
      width: 40px;
      height: 45px;
      top: 16px;
      margin-right: 16px;
      margin-left: 16px;

      &:hover {
        background-color: $color-neutral-three;
      }

      &:focus {
        outline: 2px solid $blueFocus;
      }

      &--next {
        right: 0;
      }

      &--previous {
        left: 0;
      }
    }

    &__navigation-icon::before {
      border-color: $color-brand-one;

      border-style: solid;
      border-width: 2px 2px 0 0;
      content: "";
      display: block;
      height: 18px;
      position: absolute;
      top: 2px;
      width: 18px;
    }

    &__month {
      margin: 0;
      width: 100%;
    }

    &__day-names {
      display: flex;
    }

    &__day {
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 14%;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: $font-weight-semibold;
      color: $color-neutral-one;
      margin: 0;

      &:hover {
        border-radius: 0;
      }

      &:focus {
        outline: 2px solid $blueFocus;
      }

      &--outside-month {
        opacity: 0.4;
      }

      &--selected {
        border-radius: 0;
        background-color: $color-background-datepicker-tile-selected;
      }

      &--keyboard-selected {
        border-radius: 0;
        background-color: #f0f0f0;
      }

      &--disabled {
        opacity: 0.3;
        cursor: not-allowed;

        &:focus {
          outline: none;
        }
      }
    }

    &__close-icon {
      display: none;
    }

    .react-datepicker__triangle {
      &::before,
      &::after {
        left: -35px;
      }
    }

    // Year picker
    .react-datepicker__year {
      background-color: #fff;
      margin: 0;
      padding-top: 10px;
      padding-bottom: 5px;
      position: relative;
    }

    .react-datepicker__year--container {
      z-index: 100;
      max-width: 380px;
    }

    .react-datepicker__year-wrapper {
      max-width: 100%;
      gap: 10px;
      justify-content: center;
    }

    .react-datepicker__year-text {
      color: $color-neutral-one;
      border: 1px solid $color-neutral-two;
      width: 27%;
      font-size: 14px;
      font-weight: $font-weight-semibold;
      padding: 10px;

      &--disabled {
        opacity: 0.3;
        cursor: not-allowed;

        &:focus {
          outline: none;
        }
      }
    }

    .react-datepicker__year-text--today {
      font-weight: $font-weight-semibold;
    }

    .react-datepicker__year-text--selected {
      background-color: $color-background-datepicker-tile-selected;
      border-radius: 0;
      color: $color-neutral-one;
    }

    .react-datepicker__year-text:hover,
    .react-datepicker__year-text:focus,
    .react-datepicker__year-text--keyboard-selected {
      background: $color-neutral-three;
      color: $color-neutral-one;
      border-radius: 0;
      text-decoration: underline;
    }

    .react-datepicker__year-text:focus {
      outline: 2px solid $blueFocus;
    }
  }

  .input-cleaner {
    position: absolute;
    display: flex;
    justify-content: end;
    height: 50px;
    width: 35px;
    z-index: 1;
    top: 0;
    right: 50px;
  }

  @include breakpoint(small) {
    .input-cleaner {
      right: 40px;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border-radius: 0;
    border: 1px solid #acb1b7;
    color: $color-neutral-one;
    font-family: $font-primary;
    font-size: 18px;
    padding: 3px 5px;
    cursor: pointer;
    margin-bottom: 3px;

    &:focus {
      outline: 2px solid $blueFocus;
    }
  }

  &__input {
    width: 100%;
    display: flex;
    align-items: flex-end;

    &__toggle {
      flex-basis: $toggle-button-width;
      height: $input-height;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-background-button-primary;
      color: $color-text-button-primary;
      outline: none;
      cursor: pointer;

      &--disabled {
        background: $light-grey-2;
        pointer-events: none;

        svg #dateicon {
          fill: $color-neutral-two;
        }
      }

      &:hover {
        background: $color-background-button-primary-hover;
        color: $color-text-button-primary-hover;
      }
    }
  }
}
