
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
$textArea: c-textarea;
$textAreaHeight: 100px;
$textAreaCharacterCountOffset: 20;
$textAreaCharacterCountOffsetPX: 20px;

:export {
  textAreaHeight: $textAreaHeight;
  textAreaCharacterCountOffset: $textAreaCharacterCountOffset;
}

.#{$textArea} {
  &__container {
    position: relative;
    height: calc(100% + 2px);
    border: 1px solid $color-border-input-default;

    .c-spinner__icon {
      width: 60px;
    }

    &--focus {
      outline: 2px solid $blueFocus;
    }

    &--invalid {
      border-color: $color-input-validation-error-border;
    }
  }

  &__spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  textarea {
    resize: none;
    padding: 6px 12px;
    height: $textAreaHeight;
    word-break: normal;
    outline: none;

    &:focus {
      @apply bg-white outline-none;
    }

    &.#{$textArea}__input--auto-resize {
      overflow-y: hidden;
      height: auto;
      min-height: $textAreaHeight;
    }

    &.#{$textArea}__input--no-scroll {
      overflow: hidden;
    }

    &.#{$textArea}__input--font-size-small {
      padding: 8px 8px;
      font-size: $font-size-small;
    }

    &.#{$textArea}__input--white {
      @apply bg-white;
    }

    &:focus.#{$textArea}__input--shows-character-count {
      height: $textAreaHeight - $textAreaCharacterCountOffsetPX;

      &.#{$textArea}__input--auto-resize {
        height: auto;
        min-height: $textAreaHeight - $textAreaCharacterCountOffsetPX;
      }
    }

    &:focus + .#{$textArea}__bottom-controls-container {
      top: -2px;

      .#{$textArea}__character-count {
        border-width: 2px;
        padding-top: 2px;
        padding-left: 3px;
        height: 22px;
      }
    }
  }

  &__resizable-container {
    width: 100%;
    min-width: 100%;
    min-height: $textAreaHeight;
    position: unset !important;
  }

  &--is-resized {
    z-index: 5;
    width: 100%;

    textarea {
      width: 100%;
      min-height: $textAreaHeight;
      height: 100% !important;

      &:focus.#{$textArea}__input--shows-character-count {
        min-height: $textAreaHeight - $textAreaCharacterCountOffsetPX;
        height: calc(100% - #{$textAreaCharacterCountOffsetPX}) !important;
      }
    }

    .#{$textArea}__spacer {
      height: $textAreaHeight;
    }

    .#{$textArea}__resizable-container {
      position: absolute !important;
      z-index: 5;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &__resize-handle {
    width: 16px;
    height: 17px;
  }

  &__bottom-controls-container {
    top: -2px;
    position: relative;
  }

  &__character-count {
    overflow: hidden;
    position: relative;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    width: 100%;
    height: 0;
    padding-left: 4px;
    padding-top: 1px;
    text-align: left;
    border: none;
    @apply bg-white;

    @include breakpoint(small) {
      height: $textAreaCharacterCountOffsetPX + 2px;
    }

    &--warning {
      color: $color-input-validation-warning-text;
      font-weight: $font-weight-bold;
    }

    &--error {
      color: $color-input-validation-error-text;
    }

    &--font-size-tiny {
      font-size: $font-size-tiny;
    }
  }

  .c-input-group--secondary &__character-count,
  .c-input-group--secondary &__character-count__resize-handle {
    background-color: $color-background-input-secondary;
  }

  .c-input-group--tertiary &__character-count,
  .c-input-group--tertiary &__character-count__resize-handle {
    background-color: $color-background-input-tertiary;
  }
}
