
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-tooltip {
  position: relative;
  opacity: 1;

  &__container {
    position: absolute;
    z-index: 2;
    right: 0;

    & .c-tooltip__pointer {
      right: 5px;
    }

    &.top {
      bottom: 100%;
      padding-bottom: 31px;

      & .c-tooltip__pointer {
        transform: rotate(45deg);
        bottom: -12px;
      }
    }

    &.bottom {
      top: 100%;
      padding-top: 31px;

      & .c-tooltip__pointer {
        transform: rotate(225deg);
        top: -12px;
      }
    }

    &.right {
      right: auto;
      left: 0;

      & .c-tooltip__pointer {
        left: 5px;
      }
    }

    &.stretched {
      width: 100vw;

      @include breakpoint(medium_up) {
        max-width: 33vw;
      }

      & .c-tooltip__pointer {
        display: none;
      }
    }

    @include breakpoint(medium_up) {
      width: 33vw;
    }

    @include breakpoint(small) {
      width: calc(95vw - 30px);
    }
  }

  &__container.hidden {
    display: none;
  }

  &__trigger-hover--open {
    box-shadow: 0 0 0 2px $blueFocus;
    border-radius: 4px;
  }

  &__trigger-hover:hover + &__container {
    opacity: 1;
    height: auto;
    z-index: 10;
  }

  &.active &__container {
    opacity: 1;
    height: auto;
    display: block;
    z-index: 10;
  }

  &__container {
    cursor: default;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &__section {
    padding: 0 30px 10px 10px;
    background-color: $white;
    border: solid 2px map-get($grey, 70);
    position: relative;
  }

  &__texts {
    outline: 0;
  }

  &__header {
    color: $color-text-default;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    margin: 10px 5px 5px 0;
    line-height: 27px;
  }

  &__content {
    font-size: $font-size-small;
    color: $color-text-default;
    line-height: 18px;
    white-space: pre-wrap;
    overflow-wrap: break-word; // Fallback for Safari
    overflow-wrap: anywhere;

    p:not([class]) {
      margin: 0 0 10px;
      font-size: inherit;
      color: inherit;
      line-height: inherit;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a:not([class]) {
      font-size: inherit;
    }
  }

  &__close {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 25px;
    cursor: pointer;
    color: $color-text-default;
  }

  &__pointer {
    position: absolute;
    height: 21px;
    width: 21px;
    border-right: solid 2px map-get($grey, 70);
    border-bottom: solid 2px map-get($grey, 70);
    background-color: $white;
  }
}
