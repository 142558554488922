
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-audio-player-container {
  &__close-btn {
    padding: 0;
    border: none;
    position: absolute;
    top: 13px;
    right: 13px;
  }

  &__player {
    @include breakpoint(small) {
      position: relative;
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__buttons {
    @include breakpoint(small) {
      flex-grow: 0;
      align-self: stretch;
    }
  }

  @include breakpoint(small) {
    height: 100%;
    background: $color-neutral-one;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
