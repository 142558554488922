
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-controls-container {
  margin: 10px 0;
  padding: 5px 0;

  @include breakpoint(small) {
    margin-left: -15px;
    margin-right: -15px;
  }
}