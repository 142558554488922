
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;

  &--dark {
    background-color: transparentize($color-neutral-one, 0.6);
    .c-overlay__container {
      color: $white;
    }
  }

  &--light {
    background-color: transparentize($medium-grey-3, 0.6);
    .c-overlay__container {
      color: $color-neutral-one;
    }
  }

  &__container {
    text-align: center;
  }

  &__icon {
    font-size: 48px;

    @include breakpoint(small) {
      font-size: 25px;
    }
  }

  &__title {
    line-height: 24px;
    font-size: 18px;

    @include breakpoint(small) {
      font-size: 12px;
    }
  }
}
