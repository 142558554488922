﻿/*
/ _base.links.scss
*/

a {
  text-decoration: none;
  @include primary-link;

  &.disabled-link {
    color: $color-text-disabled;
    pointer-events: none;
  }

  &:not([class]) {
    font-weight: $font-weight-semibold;
    text-decoration-skip-ink: auto;

    &:hover,
    &:active,
    &:focus {
      &:not(.disabled-link) {
        text-decoration: underline;
      }
    }
  }

  &.tel {
    color: inherit;
    text-decoration: none;
    cursor: text;

    .touchevents & {
      color: inherit;
      text-decoration: underline;
    }

    &:hover {
      color: inherit;
    }
  }
}
