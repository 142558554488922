
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.p-video-page {
  padding-top: 20px;
  padding-bottom: 50px;

  &__add-showreel {
    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  @include breakpoint(small) {
    padding-top: 10px;
  }

  &.g-content-area {
    @include breakpoint(small) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__container {
    position: relative;
  }

  &__content {
    padding: 10px 15px 0 15px;

    @include breakpoint(medium) {
      padding: 10px 10px 0 10px;
    }
  }

  &__buttons {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-flow: row-reverse;
    border-top: 1px solid rgba($color-neutral-one, 0.25);

    @include breakpoint(small) {
      flex-flow: column;
    }
  }

  &__button {
    @include breakpoint(medium_up) {
      margin-left: 20px;
      min-width: 320px;
    }

    @include breakpoint(small) {
      width: 100%;
      margin-top: 10px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
