
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
.c-edit-credits-form {
  justify-content: space-between;
  display: flex;
  flex-flow: column;

  @include breakpoint(medium_up) {
    padding: 18px 20px;
    min-height: 100vh;
  }

  @include breakpoint(small) {
    padding-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  &__header {
    line-height: 40px;
    cursor: default;
    color: $color-neutral-one;

    @include breakpoint(medium_up) {
      padding-left: 10px;
      font-size: 26px;
      padding-bottom: 16px;
    }

    @include breakpoint(small) {
      font-size: 22px;
      padding-left: 14px;
    }
  }
  &__photo {
    min-height: 200px;
    display: flex;
    background-color: $color-neutral-three;
    flex: 1;
    position: relative;
  }

  &__form {
    background-color: $color-brand-two;
    @include breakpoint(medium_up) {
      padding: 10px;
    }
  }

  &__button-panel {
    background-color: $color-brand-two;
    border-top: 1px solid $color-neutral-two;
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium_up) {
      padding: 30px;
      height: 120px;
    }
    @include breakpoint(small) {
      padding: 20px 15px;
      display: block;
      justify-content: unset;
    }
  }

  &__buttons {
    display: flex;

    @include breakpoint(medium_down) {
      width: 100%;
      justify-content: space-around;
    }

    @include breakpoint(small) {
      flex-flow: column;
    }
  }

  &__cancel {
    @include breakpoint(large) {
      margin-left: 30px;
      width: 210px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: 210px;
    }

    @include breakpoint(small) {
      width: 100%;
    }
  }

  &__continue {
    @include breakpoint(large) {
      margin-left: 30px;
      width: 210px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: 210px;
    }

    @include breakpoint(small) {
      width: 100%;
      order: -1;
      margin-bottom: 10px;
    }
  }

  &__photographer-name {
    position: relative;
  }
}
