﻿/*
/ _tools.mixin.scss
*/

//Clear fix
@mixin clearfix {
  &:after {
    content: "";
    clear: both;
    visibility: hidden;
    display: block;
    height: 0;
  }
}

//Sizing helpers
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin edge2edge() {
  margin-right: calc(
    -#{calculate-rem(map-get($grid-column-gutter, medium))} / 2
  );
  margin-left: calc(
    -#{calculate-rem(map-get($grid-column-gutter, medium))} / 2
  );
  width: calc(100% + #{calculate-rem(map-get($grid-column-gutter, medium))});
}

@mixin square($size) {
  @include size($size, $size);
}

// Truncating text
@mixin text-truncate($maxwidth) {
  display: inline-block;
  max-width: $maxwidth;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Clear for floated grids
@mixin wrap-every($n) {
  &:nth-of-type(#{$n}n + 1) {
    clear: left;
  }
}

// Reset
@mixin unwrap-every($n) {
  &:nth-of-type(#{$n}n + 1) {
    clear: none;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Arrow
/ "size" parameter set's the equal width for all borders;
/ If you want to set size explicit for each border you should set "size" parameter as 0px and then
/ specify other parameters.
/ Example:
/ To get arrow with identical border size's use it like:
/  @include arrow('bottom', $color-neutral-one, 6px);
/ To get arrow with different border sizes usd it like:
/  @include arrow('bottom', $color-neutral-one, 0px, 6px, 3px, 4px, 3px);
*/
@mixin arrow(
  $direction,
  $color,
  $size: 0px,
  $size-top: 0px,
  $size-right: 0px,
  $size-bottom: 0px,
  $size-left: 0px
) {
  display: inline-block;
  height: 0;
  width: 0;

  @if $size!=0px {
    $size-top: $size;
    $size-right: $size;
    $size-bottom: $size;
    $size-left: $size;
  }

  @if $direction== "top" {
    border-right: $size-right solid transparent;
    border-bottom: $size-bottom solid $color;
    border-left: $size-left solid transparent;
  } @else if $direction== "right" {
    border-top: $size-top solid transparent;
    border-bottom: $size-bottom solid transparent;
    border-left: $size-left solid $color;
  } @else if $direction== "bottom" {
    border-top: $size-top solid $color;
    border-right: $size-right solid transparent;
    border-left: $size-left solid transparent;
  } @else if $direction== "left" {
    border-top: $size-top solid transparent;
    border-right: $size-right solid $color;
    border-bottom: $size-bottom solid transparent;
  }
}

// Removes default browser styling for button elements
@mixin removeDefaultButtonStyling() {
  padding: 0;
  background: none;
  border: none;
}

// Removes default browser styling for ul and ol elements
@mixin removeDefaultListStyling() {
  margin: 0;
  padding: 0;
  list-style: none;
}

//Px to Rem
@function calculate-rem($size) {
  $remSize: $size / $font-size-base;
  @return #{$remSize}rem;
}

//Font-size Px to Rem
@mixin font-size-rem($size) {
  font-size: $size; //Fallback in px  font-size: calculate-rem($size);
}

// Transition animation mixin
@mixin defaultTransition(
  $transitionTarget: all,
  $transitionSpeed: 0.2s,
  $transitionType: ease-in-out
) {
  transition: $transitionTarget $transitionSpeed $transitionType;
}

@mixin globalOuterPadding() {
  @include breakpoint(large) {
    padding: 0 $outer-padding-lg-screen;
  }
  @include breakpoint(medium) {
    padding: 0 $outer-padding-md-screen;
  }
  @include breakpoint(small) {
    padding: 0 $outer-padding-sm-screen;
  }
}

@mixin input-style-disabled-override {
  pointer-events: none;
  background-color: $color-neutral-three;
  outline: 1px solid $medium-grey-3;
  color: $color-neutral-two;
}

// get unitless number
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Grid mixins
@mixin grid-content-area {
  display: grid;
  grid-column-gap: calculate-rem($columnGridGapOnLarge);
  @include breakpoint(medium) {
    grid-column-gap: calculate-rem($columnGridGapOnMedium);
  }
  @include breakpoint(small) {
    grid-column-gap: 0;
  }
}

// Rich Text mixins
$rte-margin: 1em;
$rte-padding-start: 40px;

$disc-list-style: disc;
$numeric-list-style: numeric;

@mixin rich-text-list {
  display: block;
  margin-block-start: $rte-margin;
  margin-block-end: $rte-margin;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: $rte-padding-start;
}

@mixin rich-text-list-ol {
  @include rich-text-list;
  list-style-type: $numeric-list-style;
}

@mixin rich-text-list-ul {
  @include rich-text-list;
  list-style-type: $disc-list-style;
}

@mixin primary-link {
  color: $color-brand-one;

  &:active {
    color: $color-brand-two;
    text-decoration: underline;
  }
}

@mixin secondary-link {
  color: $color-brand-two;

  &:active {
    color: $color-neutral-one;
    text-decoration: underline;
  }
}
